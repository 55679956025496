import React,{useState} from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from 'react-redux';
import "./style.scss";
import PASSWORD_HIDE_ICON from '../../assets/icons/hide.svg';
import PASSWORD_EYE from '../../assets/icons/eye.svg';
import validator from "./validator";
import { Form, InputSubmit, Input } from '../../components/atoms';
import { STRINGS } from '../../shared/constants';
import { onSubmitFail } from '../../helpers';

const PasswordForm = ({
    handleSubmit = () => { },
    initialize,
    credentials = {
        email: '',
        password: ''
    },
    onEmailChange = () => { },
    onPasswordChange = () => { },
    onSubmit = () => { }
}) => {
    const [existingVisible, setExistingVisible] = useState(false);
    const [newVisible, setNewVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    return (
        <Form onSubmit={
            handleSubmit(onSubmit)}>            
            <div className="row">
                <Field
                    name={STRINGS.OLDPASSWORD_INPUT_NAME}
                    component={Input}
                    label={STRINGS.EXISTING_PASSWORD_LABEL}
                    placeholder={STRINGS.EXISTING_PASSWORD_PLACEHOLDER}
                    config={{
                        type: existingVisible?'text':"password",
                    }}
                />
                <span onClick={() => setExistingVisible(!existingVisible)}>
                                <img src={existingVisible ? PASSWORD_HIDE_ICON :
                                    PASSWORD_EYE
                                }
                                    alt="ECR" className="img-fluid eye-icon" width="20px"
                                /> </span>
            </div>
            <div className="row">
                <Field
                    name={STRINGS.NEWPASSWORD_INPUT_NAME}
                    component={Input}
                    label={STRINGS.NEW_PASSWORD_LABEL}
                    placeholder={STRINGS.NEW_PASSWORD_PLACEHOLDER}
                    config={{
                        type: newVisible?'text':"password",
                    }}
                />
                <span onClick={() => setNewVisible(!newVisible)}>
                                <img src={newVisible ? PASSWORD_HIDE_ICON :
                                    PASSWORD_EYE
                                }
                                    alt="ECR" className="img-fluid eye-icon" width="20px"
                                /> </span>
            </div>
            <div className="row">
                <Field
                    name={STRINGS.CONFIRM_PASSWORD_INPUT_NAME}
                    component={Input}
                    label={STRINGS.CONFIRM_PASSWORD_LABEL}
                    placeholder={STRINGS.CONFIRM_PASSWORD_PLACEHOLDER}
                    config={{
                        type: confirmVisible?'text':"password",
                    }}
                />
                <span onClick={() => setConfirmVisible(!confirmVisible)}>
                                <img src={confirmVisible ? PASSWORD_HIDE_ICON :
                                    PASSWORD_EYE
                                }
                                    alt="ECR" className="img-fluid eye-icon" width="20px"
                                /> </span>
            </div>
            <div className="row">
                    <InputSubmit buttonLabel={STRINGS.BUTTON_UPDATE_PASSWORD} buttonStyle={"mt-3 ml-3"} />
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    return {

    };
}

const reduxFormFunction = reduxForm({
    form: "changePassword",
    fields: ['oldPassword','newPassword','confirmPassword'],
    onSubmitFail,
    validate: validator,
    enableReinitialize: true
})(PasswordForm);

export const ChangePasswordForm = connect(mapStateToProps, null)(reduxFormFunction);