import React from 'react';
import './style.scss';
import appLogo from '../../../assets/img/admin_logo.png';
import hamburgerIcon from '../../../assets/icons/hamburger_icon.svg';
import { ROLE_TYPES } from '../../../shared/constants';

export const AppHeader = ({
    menuItems,
    userData,
    hamburgerVisible = false,
    onHamburgerClick = () => { },
    profileLogoClick = () => { }
}) => {
    return(
        <header className="fixed-top">
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row">
                <div className="navbar-brand-wrapper d-flex align-items-center">
                    <span className="navbar-brand brand-logo" >
                        <img src={appLogo} width="150" alt="ECR" />
                    </span>
                </div>

                <div className="navbar-menu-wrapper d-flex align-items-center flex-grow-1">

                    <ul className="navbar-nav navbar-nav-right ml-auto">

                        <li className="dropdown nav-user">
                            <div className="d-flex align-items-center nav-user-img">
                                <div className="nav-user-info text-right">
                                    <h6 className="mb-0 nav-user-name text-capitalize">{userData&&userData.role===ROLE_TYPES.admin?'Admin':userData&&userData.name?userData.name:''}</h6>
                                    <span>{userData&&userData.role===ROLE_TYPES.admin?'':'Staff'}</span>
                                </div>
                                <div onClick={profileLogoClick} className="ml-2" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge" focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill="#fffff">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
                                    </svg>
                                </div>
                                <div className="dropdown-menu dropdown-menu-right nav-user-dropdown" aria-labelledby="navbarDropdownMenuLink2">
                                    <span className="dropdown-item" href="#">Logout</span>
                                </div>
                            </div>
                        </li>

                        {hamburgerVisible && <span className="humburger_icon">
                            <i><img onClick={(e) => {
                                onHamburgerClick(e)
                            }} src={hamburgerIcon} alt="hamburger" width="26" />
                            </i>
                        </span>}

                        {/* <li className="nav-item dropdown d-xl-inline-flex user-dropdown profile-drop">
                            <CustomMenu
                                menuItems={menuItems}
                            >
                                <span> Tom Cruise </span>
                                {<img
                                    src={require(`../../../../assets/profile-pics/pic1.jpg`)}
                                    width="60"
                                    className="img-xs rounded-circle ml-2"
                                    alt="SEF" />
                            </CustomMenu>
                        </li>  */}
                    </ul>

                </div>
            </nav>
        </header>
    );}