import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import {
  PAGE_TITLES,
  PAGE_LIMITS,
  LAYOUTS,
  ROUTES,
  STRINGS,
  TABLE_ARROW_DOWN,
} from "../../shared/constants";
import {
  CustomTable,
  CustomPagination,
  DecisionPopup,
} from "../../components/atoms";
import "./style.scss";
import { SnackbarWrapper } from "../../components/molecules";
export const Screen = ({
  history,
  stopLoader,
  agencyProfile,
  agencyTokens,
  agencyUpcomingTrips,
  agencyPastTrips,
  deleteAgencyDetails,
  fetchAgencyPastTrips,
  fetchAgencyUpcomingTrips,
  fetchAgencyProfile,
  fetchAgencyTokens,
}) => {
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [popupVisible, setPopVisible] = useState(false);
  const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);
  const [currentPagePast, setCurrentPagePast] = useState(1);
  const [sortKey, setSortKey] = useState("name");
  // const [lastActivePagePast, setLastActivePagePast] = useState(1);
  // const [lastActivePageUpcoming, setLastActivePageUpcoming] = useState(1);
  const sortType= 1;
  const agencyId = history.location.search.split("=")[1];
  const [snackbarData, setSnackBarData] = useState({
    variant: "",
    message: "",
  });
  const currentIndexUpcoming = 0;
  const currentIndexPast = 0;
  const [currentBranchPage, setCurrentBranchPage] = useState(1);
  
  const [agencyBranches, setAgencyBranches] = useState([]);
  const [toShowBranches, setToShowBranches] = useState([]);
  
  const PAGE_SIZE = PAGE_LIMITS.agencyList;
  useEffect(()=>{
    const {branches} = agencyProfile;
    if(branches && branches.length) {
      const newBranches = branches.map(branch => {
        return {
          name: branch.name,
          email: branch.email,
          phone: branch.phoneNumber.code + " " + branch.phoneNumber.phone,
          address: branch.address
        }
      })

      setAgencyBranches(newBranches)
    }
  }, [agencyProfile])

  useEffect(()=> {
    const newToShowBranches = agencyBranches.slice((currentBranchPage-1) * PAGE_SIZE, (currentBranchPage) * PAGE_SIZE)
    setToShowBranches(newToShowBranches)
  }, [agencyBranches, currentBranchPage, PAGE_SIZE])
  
  useEffect(() => {
    fetchAgencyProfile(
      agencyId,
      {},
      () => {
        stopLoader();
      },
      (er) => {
        console.log("failure", er);
      }
    );
    fetchAgencyTokens(
      agencyId,
      {},
      () => {
        stopLoader();
        console.log("success");
      },
      (er) => {
        console.log("failure", er);
      }
    );
    fetchAgencyUpcomingTrips(
      agencyId,
      {
        limit: PAGE_LIMITS.agencyList,
        index: currentIndexUpcoming,
      },
      () => {
        stopLoader();
        console.log("success");
      },
      (er) => {
        console.log("failure", er);
      }
    );
    fetchAgencyPastTrips(
      agencyId,
      {
        limit: PAGE_LIMITS.agencyList,
        index: currentIndexPast,
      },
      () => {
        stopLoader();
        console.log("success");
      },
      (er) => {
        console.log("failure", er);
      }
    );
  }, []);
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{PAGE_TITLES.agencyDetails}</title>
      </Helmet>
        <div class="container-fluid">
          <div className={"card border-0 shadow"}>
            <div className={"card-body"}>
              <div class="card-header pt-0">
                <div className="row driver_title">
                  <div className="col-sm-4">
                    {agencyProfile && agencyProfile.createdBy && (
                      <div className={"driver_info"}>
                        <h3 className="text-capitalize mb-3">Agency Details</h3>
                        <h5>{agencyProfile.name}</h5>
                        <span className="email_address">
                          {agencyProfile.createdBy.email &&
                            agencyProfile.createdBy.email}
                        </span>
                        {agencyProfile.createdBy.city && (
                          <p>
                            {agencyProfile.createdBy.city &&
                              agencyProfile.createdBy.city}{" "}
                            ,{" "}
                            {agencyProfile.createdBy.country &&
                              agencyProfile.createdBy.country}
                          </p>
                        )}
                        {agencyProfile.createdBy.phoneNumber && (
                          <h6 className={"user-role"}>
                            {agencyProfile.createdBy.phoneNumber.code &&
                              agencyProfile.createdBy.phoneNumber.code}{' '}
                            {agencyProfile.createdBy.phoneNumber.phone &&
                              agencyProfile.createdBy.phoneNumber.phone}
                          </h6>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-8 text-md-right">
                    <button
                      className={"btn btn-md btn-primary"}
                      onClick={() => setPopVisible(true)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>

              <hr className="m-0" />
              <SnackbarWrapper
                visible={openSnackBar}
                onClose={() => setOpenSnackbar(false)}
                variant={snackbarData.variant}
                message={snackbarData.message}
              />
              <DecisionPopup
                modalVisibility={popupVisible}
                dialogContent={STRINGS.DELETE_CONTENT_AGENCY}
                dialogTitle={STRINGS.DELETE_AGENCY}
                confirmButtonTitle={STRINGS.CONFIRM}
                rejectButtonTitle={STRINGS.CANCEL}
                toggleDialogModal={() => setPopVisible(!popupVisible)}
                onConfirmation={() => {
                  deleteAgencyDetails(
                    {
                      agencyId: agencyId,
                    },
                    (response) => {
                      setPopVisible(false);
                      setSnackBarData({
                        variant: response.status ? "success" : "error",
                        message: response.msg,
                      });
                      history.push(ROUTES.AGENCIES);
                    },
                    (response) => {
                      setSnackBarData({
                        variant: response.status ? "success" : "error",
                        message: response.msg,
                      });
                      stopLoader();
                      setOpenSnackbar(true);
                    }
                  );
                }}
                onRejection={() => setPopVisible(false)}
              />

              <div>
                <br />
                <h5 className="table_title">
                Total Tokens:{' '}{agencyTokens ? agencyTokens.tokenCount : 0}
                </h5>
              </div>
              <div className={"row pt-3"}>
                <>
                  {agencyBranches && (
                    <div className={"col-md-12 mb-3"}>
                      <h5 className="table_title">Agency Branches</h5>
                      <br />
                      <CustomTable
                        rows={toShowBranches}
                        rowsPerPage={10}
                        showProfile={false}
                        columns={LAYOUTS.ecrAgencyBranches}
                        activePage={1}
                        sortByKey={sortKey}
                        sortType={sortType}
                        onEditClick={(data) => {}}
                        onDeleteClick={(id) => {}}
                        emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                        setSortKey={setSortKey}
                      />
                      {agencyBranches &&
                        agencyBranches.length > PAGE_SIZE && (
                          <CustomPagination
                            limit={PAGE_SIZE}
                            currentPage={currentBranchPage}
                            itemsCount={
                              toShowBranches && (toShowBranches.length > 0)
                                ? toShowBranches.length
                                : 0
                            }
                            totalPages={agencyBranches.length}
                            onPageChange={(page) => {
                              setCurrentBranchPage(page.selected + 1);                             
                            }}
                          />
                        )
                      }
                    </div>
                  )}
                  <br />
                  {agencyUpcomingTrips && (
                    <div className={"col-md-12 mb-3"}>
                      <br />
                      <h5 className="table_title">Agency Upcoming Trips</h5>
                      <br />
                      <CustomTable
                        rows={agencyUpcomingTrips ? agencyUpcomingTrips.trips : []}
                        rowsPerPage={10}
                        showProfile={false}
                        columns={LAYOUTS.trips}
                        activePage={1}
                        sortByKey={sortKey}
                        sortType={sortType}
                        onEditClick={(data) => {}}
                        onDeleteClick={(id) => {}}
                        emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                        setSortKey={setSortKey}
                      />
                      {agencyUpcomingTrips &&
                        agencyUpcomingTrips.totalCount > PAGE_LIMITS.agencyList && (
                          <CustomPagination
                            limit={ PAGE_LIMITS.agencyList }
                            currentPage={currentPageUpcoming}
                            itemsCount={
                              agencyUpcomingTrips && agencyUpcomingTrips.trips
                                ? agencyUpcomingTrips.trips.length
                                : 0
                            }
                            totalPages={agencyUpcomingTrips.totalCount}
                            onPageChange={(page) => {
                              setCurrentPageUpcoming(page.selected + 1);
                              // setLastActivePageUpcoming(page.selected + 1);
                              fetchAgencyUpcomingTrips(
                                agencyId,
                                {
                                  limit:  PAGE_LIMITS.agencyList,
                                  index: page.selected,
                                },
                                () => {
                                  stopLoader();
                                  console.log("success");
                                },
                                (er) => {
                                  console.log("failure", er);
                                }
                              );
                            }}
                          />
                        )}
                    </div>
                  )}
                  {agencyPastTrips && (
                    <div className={"col-md-12"}>
                    <br/>
                      <h5 className="table_title">Agency Past Trips</h5>
                      <br />
                      <CustomTable
                        rows={agencyPastTrips ? agencyPastTrips.trips : []}
                        rowsPerPage={10}
                        showProfile={false}
                        columns={LAYOUTS.trips}
                        activePage={1}
                        sortByKey={sortKey}
                        sortType={sortType}
                        onEditClick={(data) => {}}
                        onDeleteClick={(id) => {}}
                        emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                        setSortKey={setSortKey}
                      />
                      {agencyPastTrips && agencyPastTrips.totalCount > PAGE_LIMITS.agencyList && (
                        <CustomPagination
                          limit={ PAGE_LIMITS.agencyList }
                          currentPage={currentPagePast}
                          itemsCount={
                            agencyPastTrips && agencyPastTrips.trips
                              ? agencyPastTrips.trips.length
                              : 0
                          }
                          totalPages={agencyPastTrips.totalCount}
                          onPageChange={(page) => {
                            setCurrentPagePast(page.selected + 1);
                            // setLastActivePagePast(page.selected + 1);
                            fetchAgencyPastTrips(
                              agencyId,
                              {
                                limit:  PAGE_LIMITS.agencyList,
                                index: page.selected,
                              },
                              () => {
                                stopLoader();
                                console.log("success");
                              },
                              (er) => {
                                console.log("failure", er);
                              }
                            );
                          }}
                        />
                      )}
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  );
};
