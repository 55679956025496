import React from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from 'react-redux';
import "./style.scss";

import validator from "./validator";
import { Form, InputSubmit, Input} from '../../components/atoms';
import { STRINGS } from '../../shared/constants';
import { onSubmitFail } from '../../helpers';

const StaffForm = ({
    editData,
    handleSubmit = () => { },
    initialize,
    credentials = {
        email: '',
        password: ''
    },
    onCancel = () => { },
    onSubmit = () => { }
}) => {
    return (
        <Form onSubmit={
            handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.NAME_INPUT}
                    component={Input}
                    label={STRINGS.NAME_LABEL}
                    placeholder={STRINGS.NAME_PLACEHOLDER}
                    type={'text'}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.EMAIL_INPUT_NAME}
                    component={Input}
                    label={STRINGS.EMAIL_LABEL}
                    placeholder={STRINGS.EMAIL_PLACEHOLDER}
                    type={'text'}
                />
            </div>

            <div className="row">
                <div className="col-12 text-center">
                    <button type={'button'} className={'btn btn-md reject-button mr-2'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                    <InputSubmit buttonLabel={editData&&editData.name?STRINGS.BUTTON_EDIT_STAFF:STRINGS.BUTTON_ADD_STAFF} />
                </div>
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    let initialValues = {
        'name': props.editData && props.editData.name ? props.editData.name : '',
        'email': props.editData && props.editData.email ? props.editData.email : '',
    };

    return {
        initialValues: initialValues,
    };
}

const reduxFormFunction = reduxForm({
    form: "login",
    fields: ['email', 'password'],
    onSubmitFail,
    validate: validator,
    enableReinitialize: true
})(StaffForm);

export const AddStaffForm = connect(mapStateToProps, null)(reduxFormFunction);