export const STRINGS = {
  SOMETHING_WENT_WRONG: "Something went wrong!",
  EMAIL_INPUT_NAME: 'email',
  EMAIL_REQUIRED: 'Email is required.',
  PASSWORD_INPUT_NAME: 'password',
  NAME_INPUT: 'name',
  PASSWORD_REQUIRED: 'Password is required.',
  PASSWORD_MINLENGTH: 'Password must be of minimum 6 characters.',
  EMAIL: 'Email',
  PHONE: 'Phone',
  ADDRESS: 'Address',
  PASSWORD: 'Password',
  PASSWORD_LABEL: 'Password',
  ACTION:'Action',
  EXISTING_PASSWORD_LABEL: 'Existing Password',
  EXISTING_PASSWORD_PLACEHOLDER: 'Enter your existing password.',
  NEW_PASSWORD_LABEL: 'New Password',
  NEW_PASSWORD_PLACEHOLDER: 'Enter your new password.',
  CONFIRM_PASSWORD_LABEL: 'Confirm Password',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm your password.',
  EMAIL_LABEL: 'Email',
  NAME_LABEL: 'Name',
  NAME_REQUIRED: 'Name is required.',
  NAME_PLACEHOLDER: 'Name',
  EMAIL_PLACEHOLDER: 'Email',
  PASSWORD_PLACEHOLDER: 'Password',
  KEEP_ME_SIGNED_IN_CHECKBOX_NAME: 'Remember me',
  KEEP_ME_SIGNED_IN: 'Keep me signed in',
  // FORGOT_PASSWORD: 'Forgot Password?',
  FORGOT_USERNAME: 'ForgotUsername?',
  BUTTON_LABEL_LOGIN: 'Log In',
  BUTTON_ADD_STAFF: 'Add Staff Member',
  BUTTON_UPDATE_PASSWORD: 'Update Password',
  REMEMBER_ME: 'Remember me',
  EMAIL_INVALID: 'Email is invalid.',
  NAME_INVALID: 'Name is invalid.',
  LOG_IN: 'Log In?',
  FORGOT_LOGIN: 'Log in',
  CHECK_YOUR_MAIL: 'Check your mail!',
  EMAIL_RESPONSE_MESSAGE: 'Reset password link has been sent to your email address.',
  SHARE_EMAIL_MESSAGE: 'Enter your email address, we will send you a link to reset your password.',
  LOGOUT: 'Logout',
  DASHBOARD: 'Dashboard',
  HOME: 'Home',
  CANCEL: 'Cancel',
  NAME: 'Name',
  DELETE_DRIVER: 'Delete driver',
  DELETE_AGENCY: 'Delete Agency',
  APPROVE_AGENCY: 'Approve Agency',
  REJECT_AGENCY:'Reject Agency',
  PHONE_CODE_MISMATCH_WITH_COUNTRY: 'Phone Code and Country mismatch.',
  FREE_DAYS_LABEL: 'Free days',
  MAKE_REQUEST_BTN_LABEL: 'MAKE A REQUEST',
  ROLE: 'Role',
  NEWS_FEEDS: 'News Feeds',
  DISPLAYING: 'Displaying',
  OUT_OF: 'out of',
  ALL: 'All',
  DRIVERS: 'Drivers',
  REQUEST: 'YOUR REQUEST',
  MEDIA: 'Media',
  SECURITY: 'Security',
  // NO_RECORDS_FOUND: 'No matched records found',
  NO_OF_ROWS: 'No. of Rows',
  ACCEPT_USER_CONTENT: 'Are you sure you want to approve this user?',
  ACCEPT_USER: 'Approve User',
  REJECT_USER_CONTENT: 'Are you sure you want to reject this user?',
  REJECT_USER: 'Reject User',
  USERS: 'Users',
  REQUESTS: 'Requests',
  ADDITIONAL_INFO_LABEL: 'Additional Information:',
  STAFF_PAGE_TITLE: 'ECR Staff',
  FEEDS_PAGE_TITLE: 'ECR Feeds',
  USERS_PAGE_TITLE: 'ECR Users',
  REQUESTS_PAGE_TITLE: 'ECR Users Requests',
  TESTS_PAGE_TITLE: 'ECR Tests',
  TRIP_DETAILS_LABEL: 'Trip Details:',
  DRIVER_TRIP_LABEL: 'Trips',
  UPCOMING_TRIP_LABEL: 'Upcoming Trips:',
  PAST_TRIP_LABEL: 'Past Trips:',
  EVENTS_PAGE_TITLE: 'ECR Events',
  FORGOT_PAGE_TITLE: 'ECR Forgot Password',
  LOGIN_PAGE_TITLE: 'ECR Login',
  REGISTER_PAGE_TITLE: 'ECR Register',
  USER_DETAIL_PAGE_TITLE: 'ECR User Details',
  COST_SUMMARY_LABEL: 'Cost Summary',
  TRAVEL_DATE: 'Select your travel dates:',
  USERS_TITLE: 'Users -',
  EVENT_TITLE: 'Events ',
  EMERGENCY_CONTACTS: 'Emergency Contacts -',
  STATUS: 'Status',
  ACTIVE: 'Active',
  REGISTERED: 'Registered',
  REQUESTED: 'Requested',
  INVITED: 'Invited',
  IMPORT_CSV: 'Import CSV',
  FREE_DAYS_AVAILABLE_LABEL: 'Free days available:',
  EXTRA_PAID_DAYS_AVAILABLE_LABEL: 'Extra paid days available:',
  FILE_TYPE_REJECTED: 'Selected file format is not supported! Please choose .csv file format',
  DONE: 'done',
  REJECTED_FILE_TYPE: 'rejected_file_type',
  SENDEMAIL: 'Send Email',
  // OKAY: 'Okay',
  PICKUPFROM_LABEL: 'Pick-up from:',
  PICKUPTIME_LABEL: 'Pick-up time:',
  TRAVEL_DIST_LABEL: 'Estimated travel distance',
  KMALLOW_LABEL: 'Kilometres allowance',
  DROP_OFFTIME_LABEL: 'Drop-off time:',
  USERS_LIST: 'Users List',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  DATE: 'Date',
  START_TIME: 'Time',
  EVENT_NAME: 'Event Name',
  START_DATE_REQUIRED: 'Start date is required.',
  END_DATE_REQUIRED: 'End date is required.',
  ADMIN_NAME_REQUIRED: 'Admin name is required.',
  DESCRIPTION_PLACEHOLDER: 'Description',
  CHANGE_PASSWORD: 'Change your password',
  ATTEMPT: 'Attempt(s)',
  PERCENTAGE: 'Percentage',
  ADMIN_NAME_PLACEHOLDER: 'Enter Admin name',
  ADMIN_EMAIL_PLACEHOLDER: 'Enter Admin email',
  EVENT_LIST: 'Events List',
  EMPTY_TEST_HEADING: "It seems you haven’t added any test.",
  EMPTY_CREATE_TEST_HEADING: "It seems you haven’t created any test.",
  EMPTY_TEST_DESCRIPTION: `You can add test by clicking on the button below.`,
  ADD_TEST: 'Add Test',
  TEST_INSTRUCTION: "instruction",
  TEST_INSTRUCTION_PLACEHOLDER: "Type instruction here",
  TEST_INSTRUCTION_LABEL: "Instructions",
  TEST_TITLE: "Test",
  TEST_INSTRUCTION_REQUIRED: "Test instruction is required.",
  USER_TYPE: "user_type",
  QUESTION_TYPE: "question_type",
  USER_TYPE_LABEL: "User Type",
  QUESTION_TYPE_LABEL: "Question Type",
  QUESTION_TITLE: "question_title",
  QUESTION_TITLE_LABEL: "Question Title",
  TYPE_HERE: "Type here...",
  RIGHT_ANSWER_OPTIONS: "right answer option",
  ANSWER_OPTIONS: "answer option",
  TEST_TYPE: "Test Type",
  NO_OF_QUESTION: "No. of Question(s)",
  ANSWER_TYPE: "answer_type",
  QUESTION_TIME: 'question_time',
  QUESTION_TIME_LABEL: 'Question Time ',
  MINUTE_INPUT: 'question_minute',
  SECONDS_INPUT: 'question_seconds',
  FILE: 'file',
  ANSWER_TYPE_LABEL: "Answer Type",
  USER_TYPE_REQUIRED: "User type is required",
  QUESTION_TYPE_REQUIRED: "Question type is required.",
  ANSWER_TYPE_REQUIRED: "Answer type is required.",
  QUESTION_TITLE_REQUIRED: "Question title is required.",
  ANSWER_OPTION_LIST: "answer",
  ANSWER_LIST: "answer_list",
  RIGHT_OPTION_LIST: "right_option_list",
  EMERGENCY_ACCESS: 'Emergency Access',
  AMBASSADORS: 'Ambassadors',
  ACCESS: 'Access',
  LOG_OUT: 'Log Out',
  ATTENDEES: 'Attendees',
  SUCCESS: 'success',
  ERROR: 'error',
  SEARCH: 'Search',
  USER_TYPE_COLON: 'User Type: ',
  LOCATION: 'Location',
  PEOPLE_ARE_ATTENDING: 'Attendee(s)',
  SEE_ALL: 'See all',
  ADD_EVENT: 'Add Event',
  ADD_QUESTION: 'Add Question',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE: 'Are you sure you want to delete this event?',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_QUESTION: 'Are you sure you want to delete this question?',
  DELETE: 'Delete Event',
  DELETE_QUESTION: 'Delete Question',
  EMPTY_EVENT_HEADING: `It seems you haven't added any event yet.`,
  EMPTY_EVENT_DESCRIPTION: 'You can add events by clicking on the button below.',
  EMPTY_QUESTIONS_HEADING: `It seems you haven't added any question yet.`,
  EMPTY_QUESTION_DESCRIPTION: `You can add questions by clicking on the button below.`,
  OVERALL_SCORE: 'Your Overall Score is',
  ADD_EMERGENCY_USERS: 'Add Emergency Users',
  SAVE: 'Save',
  NA: 'NA',
  LOGIN: 'Login',
  LOGIN_TITLE: 'ECR Login',
  LOGOUT_CONTENT: 'Are you sure you want to logout?',
  DELETE_CONTENT: 'Are you sure you want to delete this driver?',
  DELETE_CONTENT_AGENCY: 'Are you sure you want to delete this agency?',
  APPROVE_AGENCY_CONTENT: 'Are you sure you want to approve this agency?',
  REJECT_AGENCY_CONTENT:'Are you sure you want to reject this agency?',
  CONFIRM: 'Confirm',
  DISCOUNT_COL_ID:'discountValue',
  FORGOT_PASSWORD_TITLE: 'ECR Forgot Password',
  ECR_STAFF: 'ECR Staff',
  NO_RECORDS_FOUND: 'No matched records found.',
  NAME_COL_ID: 'name',
  EMAIL_COL_ID: 'email',
  PHONE_COL_ID: 'phone',
  ADDRESS_COL_ID: 'address',
  ACTION_COL_ID: 'action',
  DELETE_STAFF_CONTENT: 'Are you sure you want to delete this staff member?',
  DELETE_STAFF_MEMBER: 'Delete Staff Member',
  DELETE_PROMO_CONTENT: 'Are you sure you want to delete this promo code?',
  DELETE_PROMOCODE: 'Delete Promocode',
  AGENCIES: 'Agencies',
  ECR_DRIVER_TITLE: 'ECR Driver',
  ECR_AGENCIES_TITLE: 'ECR Agencies',
  BUTTON_EDIT_STAFF: 'Update Staff Details',
  OLDPASSWORD_INPUT_NAME: 'oldPassword',
  NEWPASSWORD_INPUT_NAME: 'newPassword',
  CONFIRM_PASSWORD_INPUT_NAME: 'confirmPassword',
  PASSWORD_DO_NOT_MATCH: 'Passwords do not match.',
  ECR_PROFILE_TITLE: 'Profile',
  EXISTING_PASSWORD_REQUIRED: 'Old password is required.',
  NEW_PASSWORD_REQUIRED: 'New password is required.',
  RE_ENTER_YOUR_NEW_PASSWORD: 'Confirm password is required.',
  PASSWORD_LENGTH_VALIDATION: 'Password must be atleast 6 characters long.',
  SEND_EMAIL: 'Send Email',
  EMAIL_DRIVERS: 'Email Drivers',
  EMAIL_AGENCIES: 'Email Agencies',
  PROMO_SETTING: 'Promotion Settings',
  PROMO_CODE: 'Promotion Code',
  PROMO_VALUE: 'Promotion Value (%)',
  PROMOCODE_COL_ID: 'code',
  PROMOVAL_COL_ID: 'value',
  LISTING_SETTING: 'Listing Settings',
  TOKEN_PRICE_SETTING: 'Token Price Settings',
  ADD_PROMO_CODE: 'Add Promo Code',
  CODE_NAME: 'code',
  CODE_VALUE_NAME: 'value',
  CODE_VALUE_DESCRIPTION: 'description',
  PROMOCODE_INVALID: 'Invalid Promo code name.',
  CODE_LABEL: 'Code',
  CODE_PLACEHOLDER: 'Enter code...',
  CODE_VALUE_LABEL: 'Code Value',
  CODE_VALUE_PLACEHOLDER: 'Enter code value...',
  CODE_DESCRIPTION_LABEL: 'Code Description',
  CODE_DESCRIPTION_PLACEHOLDER: 'Enter code description...',
  BUTTON_ADD_PROMOCODE: 'Add Promocode',
  BUTTON_EDIT_PROMOCODE: 'Edit Promocode',
  IS_ACTIVE_COL_ID: 'active',
  TOKENS: 'No. of Tokens',
  DISCOUNT_VALUE:'Discount Value',
  TOKENS_COL_ID: 'tokens',
  AMOUNT_COL_ID: 'amount',
  AMOUNT: 'Amount',
  AMOUNT_PLACEHOLDER: 'Enter updated amount...',
  DISCOUNT_PLACEHOLDER:'Ente updated discount...',
  BUTTON_UPDATE_AMOUNT: 'Update Amount/Discount',
  DRIVER_DETAILS: 'Driver Details',
  AGENCY_COL_ID: 'agency',
  AGENCY: 'Agency',
  AGENCY_DETAILS:'Agency Details',
  DATE_COL_ID: 'date',
  FROM_COL_ID: 'from',
  TO_COL_ID: 'to',
  FROM: 'From',
  TO: 'To',
  AGENCY_NAME: 'agencyName',
  DROPOFF_BRANCH: 'Dropoff Branch',
  DROPOFF_BRANCH_NAME: 'dropoffBranch',
  DROPOFF_DATE_ID: 'dropoffDate',
  PICKUP_BRANCH: 'Pickup Branch',
  FREE_DAYS_ID: 'freeDays',
  PICKUP_DATE_ID: 'pickupDate',
  PICKUP_BRANCH_NAME: 'pickupBranch',
  FREE_DAYS: 'Free days',
  VEHICLE_NAME: 'vehicle',
  VEHICLE: 'Vehicle',
  DROPOFF_DATE: 'Dropoff date',
  PICKUP_DATE: 'Pickup date',
  STATUS_COL_ID: 'status',
  ACTIONS_COL_ID: 'actions',
  ACTIONS: 'Action',
  CHARGE_USER_TITLE: "Charge User",
  CHARGE_USER_INPUT_PLACEHOLDER: "Enter amount",
  CHARGE_USER_SUBTITLE: "Entered amount is in NZD"
};
