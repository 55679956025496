export const GET_PROMO_LIST = 'GET_PROMO_LIST';
export const SET_PROMO_LIST = 'SET_PROMO_LIST';
export const ADD_NEW_PROMO = 'ADD_NEW_PROMO';
export const UPDATE_PROMO = 'UPDATE_PROMO';
export const DELETE_PROMO_CODE = 'DELETE_PROMO_CODE';

export const getPromoList = (data,success,failure) => {
    return {
        type: GET_PROMO_LIST,
        data,
        success,
        failure
    };
};

export const setPromoList = data => {
    return {
        type: SET_PROMO_LIST,
        data
    };
};

export const addPromo = (data, success, failure) => {
    return {
        type: ADD_NEW_PROMO,
        data,
        success,
        failure
    };
};

export const updatePromoCode = (promoId, data, success, failure) => {
    return {
        type: UPDATE_PROMO,
        promoId,
        data,
        success,
        failure
    };
};

export const deletePromoCode = (promoId, success, failure) => {
    return {
        type: DELETE_PROMO_CODE,
        promoId,
        success,
        failure
    };
};