export const ROUTES = {
    ROOT:'/',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot_password',
    DASHBOARD:'/dashboard',
    LOGOUT: '/logout',
    ECR_STAFF: '/staff',
    AGENCIES: '/agencies',
    DRIVERS: '/drivers',
    PROFILE: '/profile',
    PROMO_SETTING: '/promo_setting',
    LISTING_SETTING: '/listing_setting',
    TOKEN_PRICE_SETTING: '/token_price_setting',
    DRIVER_DETAILS: '/driver-details',
    AGENCY_DETAILS:'/agency-details',
};
