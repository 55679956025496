export const GET_PACKAGES = 'GET_PACKAGES';
export const SET_PACKAGES = 'SET_PACKAGES';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';

export const getPackagesList = (success, failure) => {
    return {
        type: GET_PACKAGES,
        success,
        failure
    };
};

export const setPackagesList = data => {
    return {
        type: SET_PACKAGES,
        data
    };
};

export const updatePackagePrice = (packageId, data, success, failure) => {
    return {
        type: UPDATE_PACKAGE,
        packageId,
        data,
        success,
        failure
    };
};