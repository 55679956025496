
import { REHYDRATE } from "redux-persist";
import {
    SET_AUTHORIZATION,
    CHECK_LOGIN,
    STOP_LOADER,
    START_LOADER,
    SET_USER_DATA,
    SET_PLATFORM_TYPE,
} from '../actions';

const { updateAuthToken } = require(`../../helpers/axios`);

const initialCommonState = {
    userToken: '',
    loader: false,
    userData:null,
    platformType:null
};

const CommonReducer = (state = { ...initialCommonState }, action) => {

    switch (action.type) {
        case SET_AUTHORIZATION:
            return {
                ...state,
                userToken: action.userToken
            };
        case START_LOADER:
            return {
                ...state,
                loader: true
            }
        case STOP_LOADER:
            return {
                ...state,
                loader: false
            }
        case CHECK_LOGIN:
            return {
                ...state,
            }
        case SET_USER_DATA:
            return {
                ...state,
                userData:action.data
            }
        case SET_PLATFORM_TYPE:
            return {
                ...state,
                platformType:action.platformType
            }
        case REHYDRATE:
            let common = ((action || {}).payload || {}).CommonReducer || initialCommonState
            updateAuthToken(common.userToken)

            return {
                ...state,
                userData: common.userData,
                userToken: common.userToken,
                platformType: common.platformType,
            };
        default:
            return state;
    }
};

export default CommonReducer;