import React from 'react';
import moment from 'moment';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import classNames from 'classnames';
import PASSWORD_EYE from "../../../assets/icons/eye.svg";
import editIcon from '../../../assets/icons/edit.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import './style.scss';
export const CustomTable = ({
  columns = [],
  rows = [],
  rowsPerPage = 5,
  activePage = 1,
  showProfile = false,
  isEvent = false,
  editAction = true,
  holdingArea = false,
  roleItems,
  userType = false,
  actionLeftText = 'Reject',
  actionRightText = 'Approve',
  viewMoreText = 'View More',
  TABLE_ARROW_UP,
  TABLE_ARROW_DOWN,
  ROLE_LABELS,
  emptyDataMessage,
  addContactList = [],
  sortByKey,
  sortType,
  expandRow,
  INFO_ICON,
  deleteRequired = true,
  editRequired = true,
  viewRequired = false,
  EDIT_ICON,
  DELETE_ICON,
  QUESTION_TYPE,
  onAccept = () => { },
  onReject = () => { },
  setSortKey = () => { },
  onTestReminderClick = () => { },
  onViewDetails = () => { },
  onRowClick = () => { },
  onEditClick = () => { },
  onDeleteClick = () => { },
  onInfoClick = () => { },
  onStatusChange = () => { },
  tableContainerStyle = {},
}) => {

  // const [onEdit, setOnEdit] = React.useState(false)
  // const [noOfQuestion, setNoOfQestion] = React.useState()
  // const [roleKeys, setRoleKeys] = React.useState([]);

  React.useEffect(() => {
    let value = '';
    let keysValue = []
    roleItems && roleItems.length && roleItems.map((item) => {
      value = Object.values(item);
      return keysValue.push(value[1])
    });
    // setRoleKeys(keysValue)
  }, [roleItems])
  return (
    <div className={classNames([tableContainerStyle, 'table-container'])}>
      <div className={"table-responsive"}>
        <Table className="table-borderless">
          <TableHead>
            <TableRow>
              {columns.map((item) => {
                if (item.id === 'name' || item.id === 'date' || item.id === 'startDate' || item.id === 'dateTime' || item.id === 'endDate' || (item.id === 'championship-name' && !holdingArea) || item.id === "question" || item.id === "questionType") {
                  return (
                    <TableCell
                      id={item.id + ''}
                      key={item.id}
                      align={item.align}
                      style={{ minWidth: item.minWidth, backgroundColor: "transparent" }}
                    >
                      {item.label}
                      {rows && rows.length > 1 && <span className="ad_arrows" onClick={() => setSortKey(item.id)}>
                        <img
                          style={{ opacity: (sortByKey === item.id && sortType === 1) && '1' }}
                          className={classNames(['show-challenges'])}
                          src={TABLE_ARROW_UP} alt="" />
                        <img
                          style={{ opacity: (sortByKey === item.id && sortType === -1) && '1' }}
                          className={classNames(['down-arrow'])}
                          src={TABLE_ARROW_DOWN} alt="" />
                      </span>}
                    </TableCell>
                  )
                }
                else {
                  return (
                    <TableCell
                      id={item.id + ''}
                      key={item.id}
                      align={item.align}
                      style={{ minWidth: item.minWidth, backgroundColor: "transparent" }} >
                      {item.label}
                    </TableCell>
                  )
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length ? rows.slice((activePage - 1) * rowsPerPage, (activePage - 1) * rowsPerPage + rowsPerPage).map((row, rowIndex) => {
              return (
                <React.Fragment key={`row-${rowIndex}-wrapper`}>
                  <TableRow
                    hover
                    className={'table-row-data'}
                    tabIndex={-1}
                    id={row._id}
                    onClick={() => { }}
                    key={`row-${rowIndex}`}
                  >
                    {columns.map((column, index) => {
                      if (column.id === 'date') {
                        const value = row.createdAt;
                        return (
                          <TableCell key={`${column.id}-${index}`} align={column.align}>
                            {moment(new Date(value)).format('DD/MM/YYYY')}
                          </TableCell>
                        );

                      }

                      if (column.id === 'action') {
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            <div
                              className={
                                "user-action-div"
                              }
                            >
                              {editRequired && (
                                <i
                                  className={`edit-icon mx-2`}
                                  onClick={() =>
                                    onEditClick(row)
                                  }
                                >
                                  <img
                                    src={editIcon}
                                    alt=""
                                    height={18}
                                  />
                                </i>
                              )}
                              {viewRequired && (
                                <i
                                  className={
                                    "del-icon mx-2"
                                  }
                                  onClick={() =>
                                    onViewDetails(
                                      row._id
                                    )
                                  }
                                >
                                  <img
                                    src={PASSWORD_EYE}
                                    alt=""
                                    height={18}
                                  />
                                </i>
                              )}
                              {deleteRequired && (
                                <i
                                  className={
                                    "del-icon mx-2"
                                  }
                                  onClick={() =>
                                    onDeleteClick(
                                      row._id
                                    )
                                  }
                                >
                                  <img
                                    src={deleteIcon}
                                    alt=""
                                    height={18}
                                  />
                                </i>
                              )}
                            </div>
                          </TableCell>
                        );
                      }
                      if (column.id === 'active') {
                        const checkValue = row.isActive;
                        return (
                          <TableCell key={`${column.id}`} align={column.align}>
                            <span className={'switch-button'}>
                              <label>
                                <input
                                  className={'toggle-button'}
                                  type="checkbox"
                                  onChange={(event) => {
                                    onStatusChange(event.target.checked, row._id)
                                  }}
                                  checked={checkValue}
                                  disabled={false}
                                />
                              </label>
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.id === 'name') {
                        const value = row[column.id];
                        return (
                          <TableCell key={`${column.id}-${index}`} align={column.align}>
                            <div className={classNames([
                              'user-name',
                              { 'pointer': showProfile }
                            ])} onClick={() => {
                              onRowClick(row._id)
                            }}>
                              {showProfile && <i>
                                <img src={row.profileUrl} alt="" className={'rounded-circle'} width={50} height={50} />
                              </i>}
                              <div className="user_info">
                                {value}
                                {row.ambassadorAccess && <label>{'Ambassador'}</label>}
                              </div>
                            </div>
                          </TableCell>
                        );
                      }
                      if (column.id === 'amount') {
                        const value = row[column.id];
                        return (
                          <TableCell key={`${column.id}-${index}`} align={column.align}>
                            {`$${value}`}
                          </TableCell>
                        );
                      }
                      if (column.id === "agencyName") {
                        const value = row.agency && row.agency.name ? row.agency.name : '';
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {`${value}`}
                          </TableCell>
                        );
                      }
                      if (column.id === "value") {
                        const value = row.value;
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {`${value}%`}
                          </TableCell>
                        );
                      }
                      if (column.id === "vehicle") {
                        const value = row.vehicle && row.vehicle.name ? row.vehicle.name : '';
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {`${value}`}
                          </TableCell>
                        );
                      }
                      if (column.id === "dropoffBranch") {
                        const value = row.dropoffBranch && row.dropoffBranch.name ? row.dropoffBranch.name : '';
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {`${value}`}
                          </TableCell>
                        );
                      }
                      if (column.id === "discountValue") {
                        const value = row.discountValue
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {`$${value}`}
                          </TableCell>
                        );
                      }
                      if (
                        column.id === "pickupBranch"
                      ) {
                        const value = row.pickupBranch && row.pickupBranch.name ? row.pickupBranch.name : '' ;
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {`${value}`}
                          </TableCell>
                        );
                      }
                      if (column.id === "dropoffDate") {
                        const value = row.dropoffDate;
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {moment(
                              new Date(value)
                            ).format("DD/MM/YYYY")}
                          </TableCell>
                        );
                      }
                      if (column.id === "freeDays") {
                        const value = row.freeDays;
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {`${value}`}
                          </TableCell>
                        );
                      }
                      if (column.id === "pickupDate") {
                        const value = row.pickupDate;
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {moment(
                              new Date(value)
                            ).format("DD/MM/YYYY")}
                          </TableCell>
                        );
                      }
                      if (column.id === "status") {
                        const value = row.status;
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {
                              value === 1 ? 'Pending' : value === 3 ? 'Disapproved' : 'Registered'
                            }
                          </TableCell>
                        );
                      }
                      if (column.id === 'actions') {
                        const value = row.status;
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
{                            console.log(value, viewRequired, deleteRequired, editRequired)
}                            {value === 1 ? (
                              <div>
                                 <i
                                  className={"del-icon mx-2"}
                                  onClick={() => onViewDetails(row._id)}
                                >
                                  <img
                                    src={PASSWORD_EYE}
                                    alt=""
                                    height={18}
                                  />
                                </i>
                                <button
                                  onClick={() => onAccept(row)}
                                  className={"btn btn-md btn-primary mr-2"}
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() => onReject(row)}
                                  className={"btn btn-md reject-button"}
                                >
                                  Reject
                                </button>
                              </div>
                            ) :
                             (
                              <div className={"user-action-div"}>
                              {viewRequired && (
                                <i
                                  className={"del-icon mx-2"}
                                  onClick={() => onViewDetails(row._id)}
                                >
                                  <img
                                    src={PASSWORD_EYE}
                                    alt=""
                                    height={18}
                                  />
                                </i>
                              )}
                              {deleteRequired && (
                                <i
                                  className={"del-icon mx-2"}
                                  onClick={() => onDeleteClick(row._id)}
                                >
                                 <img src={deleteIcon} alt="" height={18} />
                                </i>
                              )}
                            </div>
                            ) }
                          </TableCell>
                        );
                      }
                      else {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={`${column.id}-${index}`}
                            align={column.align}
                          >
                            {value}
                          </TableCell>
                        );
                      }

                    })}
                  </TableRow>

                </React.Fragment>
              );
            })
              :
              <TableRow>
                <TableCell colSpan={7} align={'center'}>
                  {emptyDataMessage}
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </div>
    </div >

  )
}