import React, { useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import CsvDownloader from 'react-csv-downloader';
import {
    LABELS,
    ROUTES,
    PAGE_TITLES,
    PAGE_LIMITS,
    LAYOUTS,
    STRINGS,
    TABLE_ARROW_DOWN,
    SEARCH_ICON,
} from '../../shared/constants';
import {
    CustomTable,
    DecisionPopup,
    CustomPagination
} from '../../components/atoms';
import { TextField } from '@material-ui/core';
import './style.scss';
import { SnackbarWrapper } from "../../components/molecules";
export const Screen = ({
    history,
    driverList,
    driverListPage,
    fetchDriverList,
    stopLoader,
    deleteDriverDetails,
    setDriverListPage,
}) => {
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [popupVisible, setPopVisible] = useState(false);
    // const [emailFormVisible, setEmailFormVisible] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [sortKey, setSortKey] = useState('name');
    const sortType = 1;
    const [targetId, setTargetId] = useState(null);
    const [currentPage, setCurrentPage] = useState(driverListPage);
    const filterType = 1;
    const [lastActivePage, setLastActivePage] = useState(driverListPage);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });

    useEffect(() => {
        fetchDriverList({
            limit: PAGE_LIMITS.driverList,
            pageNumber: currentPage,
            sortType,
            filterType
        }, () => {
            stopLoader();
            console.log('success')
        }, (er) => {
            console.log('failure', er)
        })
    }, []);

    return (
      <React.Fragment>
        <Helmet>
          <title>{PAGE_TITLES.drivers}</title>
        </Helmet>

        <div className="container-fluid">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-4">
                <div className="table-search">
                  <TextField
                    className="text-field"
                    label={LABELS.search}
                    type="text"
                    value={searchString}
                    placeholder={STRINGS.SEARCH}
                    onChange={(e) => {
                      fetchDriverList(
                        {
                          limit: PAGE_LIMITS.driverList,
                          pageNumber: currentPage,
                          sortType,
                          searchString: e.target.value,
                          filterType,
                        },
                        () => {
                          stopLoader();
                          console.log("success");
                        },
                        (er) => {
                          console.log("failure", er);
                        }
                      );

                      if (e.target.value === "") {
                        setCurrentPage(lastActivePage);
                        setSearchString(e.target.value);
                      } else {
                        setCurrentPage(1);
                        setSearchString(e.target.value);
                      }
                    }}
                    margin="none"
                  />
                  <i>
                    <img className="search-icon" src={SEARCH_ICON} alt={""} />
                  </i>
                </div>
              </div>
              <div className="col-sm-8 text-md-right">
                {/* <button className={'btn btn-md btn-primary'} onClick={() => setEmailFormVisible(true)}>{STRINGS.EMAIL_DRIVERS}</button> */}

                <CsvDownloader
                  className={"csv-downloader"}
                  filename={"Drivers details list"}
                  separator={","}
                  datas={driverList && driverList.data ? driverList.data : []}
                >
                  <button className={"btn btn-md btn-primary ml-2"}>
                    Download CSV
                  </button>
                </CsvDownloader>
              </div>
            </div>
          </div>

          {/* {emailFormVisible && <div className='dropzone-div'>
                            <div className="overlay"></div>
                            <div className="dropzone-dialog">
                                <div className="dropzone-content">
                                    <div className="dropzone-body">
                                    <Editor />
                                    <button className={'btn btn-md reject-button mr-2'} onClick={() => setEmailFormVisible(false)}>{STRINGS.CANCEL}</button>
                                    <button className={'btn btn-md btn-primary'} onClick={() => {}}>{STRINGS.SEND_EMAIL}</button>
                                </div>
                            </div>
                        </div>
                    </div>} */}
          <SnackbarWrapper
            visible={openSnackBar}
            onClose={() => setOpenSnackbar(false)}
            variant={snackbarData.variant}
            message={snackbarData.message}
          />
          <DecisionPopup
            modalVisibility={popupVisible}
            dialogContent={STRINGS.DELETE_CONTENT}
            dialogTitle={STRINGS.DELETE_DRIVER}
            confirmButtonTitle={STRINGS.CONFIRM}
            rejectButtonTitle={STRINGS.CANCEL}
            toggleDialogModal={() => setPopVisible(!popupVisible)}
            onConfirmation={() => {
              deleteDriverDetails(
                {
                  driverId: targetId,
                },
                (response) => {
                   setPopVisible(false);
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg,
                  });
                     fetchDriverList(
                    {
                      limit: PAGE_LIMITS.driverList,
                      pageNumber: currentPage,
                      sortType,
                      filterType,
                    },
                       () => {
                      stopLoader();
                      console.log("success");
                    },
                    (er) => {
                      console.log("failure", er);
                    }
                  );
                },
                (response) => {
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg,
                  });
                  stopLoader();
                  setOpenSnackbar(true);
                }
              );
            }}
            onRejection={() => setPopVisible(false)}
          />

          <CustomTable
            rows={driverList && driverList.data ? driverList.data : []}
            rowsPerPage={10}
            viewRequired={true}
            columns={LAYOUTS.ecrDriver}
            activePage={1}
            onViewDetails={(data) => {
              setDriverListPage(currentPage);
              history.push(`${ROUTES.DRIVER_DETAILS}?id=${data}`);
            }}
            sortByKey={sortKey}
            sortType={sortType}
            onEditClick={(data) => {}}
            editRequired={false}
                    onDeleteClick={(id) => {
                        setTargetId(id);
                        setPopVisible(true);
                    }}
            // onRowClick={(id) => {
            //     setDriverListPage(currentPage)
            //     console.log('history.push(ROUTES.DRIVER_DETAILS)', id);
            //     history.push(`${ROUTES.DRIVER_DETAILS}?id=${id}`)
            // }}
            emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
            TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
            setSortKey={setSortKey}
          />
          {driverList && driverList.totalDocuments > PAGE_LIMITS.driverList && (
            <CustomPagination
              limit={PAGE_LIMITS.driverList}
              currentPage={currentPage}
              itemsCount={
                driverList && driverList.data ? driverList.data.length : 0
              }
              totalPages={driverList.totalDocuments}
              onPageChange={(page) => {
                setCurrentPage(page.selected + 1);
                setLastActivePage(page.selected + 1);
                fetchDriverList(
                  {
                    limit: PAGE_LIMITS.driverList,
                    pageNumber: page.selected + 1,
                    sortType,
                    filterType,
                  },
                  () => {
                    stopLoader();
                    console.log("success");
                  },
                  (er) => {
                    console.log("failure", er);
                  }
                );
              }}
            />
          )}
        </div>
      </React.Fragment>
    );
}