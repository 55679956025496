import React,{useState} from "react";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import { connect } from 'react-redux';
import "./style.scss";

import validator from "./validator";
import { Form, InputSubmit, Input } from '../../components/atoms';
import { ROUTES, STRINGS } from '../../shared/constants';
import PASSWORD_HIDE_ICON from '../../assets/icons/hide.svg';
import PASSWORD_EYE from '../../assets/icons/eye.svg';
import { onSubmitFail } from '../../helpers';

const LoginForm = ({
    handleSubmit = () => { },
    initialize,
    credentials = {
        email: '',
        password: ''
    },
    onEmailChange = () => { },
    onPasswordChange = () => { },
    onSubmit = () => { }
}) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    return (
        <Form onSubmit={
            handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.EMAIL_INPUT_NAME}
                    component={Input}
                    label={STRINGS.EMAIL_LABEL}
                    placeholder={STRINGS.EMAIL_PLACEHOLDER}
                    type={'text'}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.PASSWORD_INPUT_NAME}
                    component={Input}
                    label={STRINGS.PASSWORD_LABEL}
                    placeholder={STRINGS.PASSWORD_PLACEHOLDER}
                    config={{
                        type: passwordVisible?'text':"password",
                    }}
                />
                <span onClick={() => setPasswordVisible(!passwordVisible)}>
                                <img src={passwordVisible ? PASSWORD_HIDE_ICON :
                                    PASSWORD_EYE
                                }
                                    alt="ECR" className="img-fluid eye-icon" width="20px"
                                /> </span>
            </div>
            <div className="row">
                <Link
                    className="col-sm-6 auth-link"
                    to={ROUTES.FORGOT_PASSWORD}
                >
                    {STRINGS.FORGOT_PASSWORD}
                </Link>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <InputSubmit buttonLabel={STRINGS.BUTTON_LABEL_LOGIN} buttonStyle={"mt-3"} />
                </div>
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    return {
    };
}

const reduxFormFunction = reduxForm({
    form: "login",
    fields: ['email', 'password'],
    onSubmitFail,
    validate: validator,
    enableReinitialize: true
})(LoginForm);

export const LoginReduxForm = connect(mapStateToProps, null)(reduxFormFunction);