import React from "react";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import "./style.scss";

import validator from "./validator";
import { Form, InputSubmit, Input } from '../../components/atoms';
import { onSubmitFail } from '../../helpers';
import { ROUTES, STRINGS } from '../../shared/constants';

const ForgotForm = ({
    handleSubmit,
    onSubmit = () => { }
}) => {
    return (
        <Form onSubmit={
            handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.EMAIL_INPUT_NAME}
                    component={Input}
                    label={STRINGS.EMAIL_LABEL}
                    placeholder={STRINGS.EMAIL_PLACEHOLDER}
                    type={'text'}
                // config={{
                //     type: "email",
                //     placeholder: STRINGS.EMAIL_PLACEHOLDER
                // }}
                />
            </div>
            <div className="row">
                <div className="col-12 text-center my-3">
                    <InputSubmit buttonLabel={STRINGS.SEND_EMAIL} containerStyle={"text-center"} />
                </div>
            </div>
            <div className="row">
                <Link
                    className="col-md-12 auth-link text-center"
                    to={ROUTES.LOGIN}
                >
                    {STRINGS.LOG_IN}
                </Link>
            </div>
        </Form>
    );
};

export const ForgotReduxForm = reduxForm({
    form: "forgot",
    validate: validator,
    onSubmitFail: onSubmitFail,
})(ForgotForm);