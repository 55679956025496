export const ADD_ECR_STAFF = 'ADD_ECR_STAFF';
export const GET_STAFF_LIST = 'GET_STAFF_LIST';
export const SET_STAFF_LIST = 'SET_STAFF_LIST';
export const DELETE_STAFF = 'DELETE_STAFF';
export const UPDATE_STAFF_DETAILS = 'UPDATE_STAFF_DETAILS';

export const addEcrStaff = (data, success, failure) => {
    return {
        type: ADD_ECR_STAFF,
        data,
        success,
        failure
    }
}

export const getStaffList = (data, success, failure) => {
    return {
        type: GET_STAFF_LIST,
        data,
        success,
        failure
    };
};

export const setStaffList = data => {
    return {
        type: SET_STAFF_LIST,
        data
    };
};

export const updateStaffDetail = (staffId, data, success, failure) => {
    return {
        type: UPDATE_STAFF_DETAILS,
        staffId,
        data,
        success,
        failure
    };
};

export const deleteStaff = (staffId, success, failure) => {
    return {
        type: DELETE_STAFF,
        staffId,
        success,
        failure
    };
};