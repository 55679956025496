import { STRINGS } from './strings';
import { ROUTES } from './routes';

export const LABELS = {
  forgotPassword: STRINGS.FORGOT_PASSWORD,
  login: STRINGS.LOGIN,
  login_heading: STRINGS.LOGIN_HEADING,
  email: STRINGS.EMAIL,
  phone: STRINGS.PHONE,
  name: STRINGS.NAME,
  address: STRINGS.ADDRESS,
  action:STRINGS.ACTION,
  password: STRINGS.PASSWORD,
  search:STRINGS.SEARCH,
  checkYourMail: STRINGS.CHECK_YOUR_MAIL,
  promoCode: STRINGS.PROMO_CODE,
  promoValue: STRINGS.PROMO_VALUE,
  active: STRINGS.ACTIVE,
  tokens: STRINGS.TOKENS,
  amount: STRINGS.AMOUNT,
  discountValue:STRINGS.DISCOUNT_VALUE,
  agency: STRINGS.AGENCY,
  date: STRINGS.DATE,
  from: STRINGS.FROM,
  to: STRINGS.TO,
  dropoffBranch: STRINGS.DROPOFF_BRANCH,
  pickupBranch: STRINGS.PICKUP_BRANCH,
  freeDays: STRINGS.FREE_DAYS,
  pickupDate: STRINGS.PICKUP_DATE,
  dropoffDate: STRINGS.DROPOFF_DATE,
  vehicle: STRINGS.VEHICLE,
  status: STRINGS.STATUS,
  actions:STRINGS.ACTIONS,
};

export const VALIDATION_MESSAGES = {
  EMAIL_REQUIRED: STRINGS.EMAIL_REQUIRED,
  EMAIL_IS_NOT_VALID: STRINGS.EMAIL_IS_NOT_VALID,
  PASSWORD_REQUIRED: STRINGS.PASSWORD_REQUIRED,
  PASSWORDS_DO_NOT_MATCH: STRINGS.PASSWORD_DO_NOT_MATCH,
  PASSWORD_MINLENGTH: STRINGS.PASSWORD_MINLENGTH,
  EMAIL_INVALID: STRINGS.EMAIL_INVALID,
  NAME_INVALID: STRINGS.NAME_INVALID,
  NAME_REQUIRED: STRINGS.NAME_REQUIRED,
  ADMIN_NAME_REQUIRED: STRINGS.ADMIN_NAME_REQUIRED,
  PASSWORD_LENGTH_VALIDATION: STRINGS.PASSWORD_LENGTH_VALIDATION,
  VALUE_CANNOT_BE_EMPTY_SPACES: STRINGS.VALUE_CANNOT_BE_EMPTY_SPACES,
  EXISTING_PASSWORD_REQUIRED: STRINGS.EXISTING_PASSWORD_REQUIRED,
  NEW_PASSWORD_REQUIRED: STRINGS.NEW_PASSWORD_REQUIRED,
  RE_ENTER_YOUR_NEW_PASSWORD: STRINGS.RE_ENTER_YOUR_NEW_PASSWORD,
  PROMO_CODE_INVALID:STRINGS.PROMOCODE_INVALID
};

export const PAGE_TITLES = {
  login: STRINGS.LOGIN_TITLE,
  ecrStaff: STRINGS.STAFF_PAGE_TITLE,
  forgotPassword: STRINGS.FORGOT_PASSWORD_TITLE,
  drivers: STRINGS.ECR_DRIVER_TITLE,
  driverDetails:STRINGS.DRIVER_DETAILS,
  agencies: STRINGS.ECR_AGENCIES_TITLE,
  agencyDetails:STRINGS.AGENCY_DETAILS,
  profile:STRINGS.ECR_PROFILE_TITLE
};

export const STATUS_CODE = {
  bad:400,
  successful: 200,
  unAuthorized: 401
};

export const ROLE_TYPES = {
  admin: 4,
  staff:5
}

export const ADMIN_DRAWER_ITEMS = [
  {
    tag: STRINGS.ECR_STAFF,
    alt: "drawer-icon",
    routeUrl: ROUTES.ECR_STAFF,
  },
  {
    tag: STRINGS.AGENCIES,
    alt: "drawer-icon",
    routeUrl: ROUTES.AGENCIES,
  },
  {
    tag: STRINGS.DRIVERS,
    alt: "drawer-icon",
    routeUrl: ROUTES.DRIVERS,
  },  
  {
    tag: STRINGS.PROMO_SETTING,
    alt: "drawer-icon",
    routeUrl: ROUTES.PROMO_SETTING,
  }, 
  {
    tag: STRINGS.TOKEN_PRICE_SETTING,
    alt: "drawer-icon",
    routeUrl: ROUTES.TOKEN_PRICE_SETTING,
  },  
];

export const STAFF_DRAWER_ITEMS = [
  {
    tag: STRINGS.AGENCIES,
    alt: "drawer-icon",
    routeUrl: ROUTES.AGENCIES,
  },
  {
    tag: STRINGS.DRIVERS,
    alt: "drawer-icon",
    routeUrl: ROUTES.DRIVERS,
  },  
  {
    tag: STRINGS.PROMO_SETTING,
    alt: "drawer-icon",
    routeUrl: ROUTES.PROMO_SETTING,
  },  
  {
    tag: STRINGS.TOKEN_PRICE_SETTING,
    alt: "drawer-icon",
    routeUrl: ROUTES.TOKEN_PRICE_SETTING,
  },  
];

export const ELEMENT_IDS = {
  name: STRINGS.NAME_COL_ID,
  email: STRINGS.EMAIL_COL_ID,
  phone: STRINGS.PHONE_COL_ID,
  address: STRINGS.ADDRESS_COL_ID,
  action: STRINGS.ACTION_COL_ID,
  agencyName: STRINGS.AGENCY_NAME,
  vehicleName:STRINGS.VEHICLE_NAME,
  promoCode: STRINGS.PROMOCODE_COL_ID,
  promoValue: STRINGS.PROMOVAL_COL_ID,
  active: STRINGS.IS_ACTIVE_COL_ID,
  tokens: STRINGS.TOKENS_COL_ID,
  amount: STRINGS.AMOUNT_COL_ID,
  discount:STRINGS.DISCOUNT_COL_ID,
  dropoffBranchName: STRINGS.DROPOFF_BRANCH_NAME,
  pickupBranchName: STRINGS.PICKUP_BRANCH_NAME,
  freeDaysId: STRINGS.FREE_DAYS_ID,
  pickupDateId:STRINGS.PICKUP_DATE_ID,
  dropoffDateId:STRINGS.DROPOFF_DATE_ID,
  agency: STRINGS.AGENCY_COL_ID,
  date: STRINGS.DATE_COL_ID,
  from: STRINGS.FROM_COL_ID,
  status: STRINGS.STATUS_COL_ID,
  actions:STRINGS.ACTIONS_COL_ID,
  to: STRINGS.TO_COL_ID
}

export const LAYOUTS = {
  ecrStaff: [
    {
      id: ELEMENT_IDS.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.email,
      label: LABELS.email,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.action,
      label: LABELS.action,
      minWidth: 5,
      align: "left",
    },
  ],
  ecrDriver: [
    {
      id: ELEMENT_IDS.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.email,
      label: LABELS.email,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.action,
      label: LABELS.action,
      minWidth: 5,
      align: "left",
    },
  ],
  promoList: [
    {
      id: ELEMENT_IDS.promoCode,
      label: LABELS.promoCode,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.promoValue,
      label: LABELS.promoValue,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.active,
      label: LABELS.active,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.action,
      label: LABELS.action,
      minWidth: 5,
      align: "left",
    },
  ],
  packageList: [
    {
      id: ELEMENT_IDS.tokens,
      label: LABELS.tokens,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.amount,
      label: LABELS.amount,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.discount,
      label: LABELS.discountValue,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.action,
      label: LABELS.action,
      minWidth: 5,
      align: "left",
    },
  ],
  relocations: [
    {
      id: ELEMENT_IDS.agency,
      label: LABELS.agency,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.date,
      label: LABELS.date,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.from,
      label: LABELS.from,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.to,
      label: LABELS.to,
      minWidth: 5,
      align: "left",
    },
  ],
  trips: [
    {
      id: ELEMENT_IDS.agencyName,
      label: LABELS.agency,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.vehicleName,
      label: LABELS.vehicle,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.pickupBranchName,
      label: LABELS.pickupBranch,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.dropoffBranchName,
      label: LABELS.dropoffBranch,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.pickupDateId,
      label: LABELS.pickupDate,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.dropoffDateId,
      label: LABELS.dropoffDate,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.freeDaysId,
      label: LABELS.freeDays,
      minWidth: 5,
      align: "left",
    },
  ],
  ecrAgency: [
    {
      id: ELEMENT_IDS.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.email,
      label: LABELS.email,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.status,
      label: LABELS.status,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.actions,
      label: LABELS.actions,
      minWidth: 5,
      align: "left",
    },
  ],
  ecrAgencyBranches: [
    {
      id: ELEMENT_IDS.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.email,
      label: LABELS.email,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.phone,
      label: LABELS.phone,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_IDS.address,
      label: LABELS.address,
      minWidth: 5,
      align: "left",
    },
  ],
};


export const PAGE_LIMITS = {
  staffList:5,
  driverList:5,
  agencyList:5
};