import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import {
    LABELS,
    PAGE_TITLES,
    STRINGS,
    LAYOUTS,
    EDIT_ICON,
    PAGE_LIMITS,
    DELETE_ICON,
    SEARCH_ICON,
    ROLE_TYPES,
    TABLE_ARROW_DOWN
} from '../../shared/constants';
import { AddStaffForm } from './form';
import { TextField } from '@material-ui/core';
import {
    CustomTable,
    DecisionPopup,
    CustomPagination
} from '../../components/atoms';
import { SnackbarWrapper } from '../../components/molecules';


export const Screen = ({
    staffList,
    stopLoader,
    deleteStaff,
    addEcrStaff,
    getStaffList,
    updateStaffDetail
}) => {
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [staffFormVisible, setStaffFormVisible] = useState(false);
    const [popupVisible, setPopVisible] = useState(false)
    const [sortKey, setSortKey] = useState('name');
    const [targetId, setTargetId] = useState(null);
    const [editData, setEditData] = useState(null);
    const filterType = 1;
    const [searchString, setSearchString] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastActivePage, setLastActivePage] = useState(1);
    const sortType = 1;
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    // const [credentials, setCredentials] = useState({ email: '', password: '' });

    useEffect(() => {
        getStaffList({
            limit: PAGE_LIMITS.staffList,
            pageNumber: currentPage,
            sortType,
            filterType
        }, () => {
                stopLoader()
            console.log('success')
        }, (er) => {
            console.log('failure', er)
        })
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.ecrStaff}</title>
            </Helmet>
            {staffFormVisible && <div className='dropzone-div'>
                <div className="overlay"></div>
                <div className="dropzone-dialog">
                    <div className="dropzone-content">
                        <div className="dropzone-body">
                            <h3 className="mb-4 text-center">{LABELS.addUser}</h3>
                            <AddStaffForm
                                editData={editData}
                                onSubmit={(formData) => {
                                    if (!editData) {
                                        addEcrStaff({
                                            ...formData,
                                            role: ROLE_TYPES.staff
                                        }, (response) => {
                                            setSnackBarData({
                                                variant: response.status ? 'success' : 'error',
                                                message: 'Staff added successfully.'
                                            });
                                            getStaffList({
                                                limit: PAGE_LIMITS.staffList,
                                                pageNumber: currentPage,
                                                sortType,
                                                filterType
                                            }, () => {
                                                stopLoader();
                                                setStaffFormVisible(false);
                                                setOpenSnackbar(true);
                                            }, (er) => {
                                                stopLoader();
                                                setStaffFormVisible(false);
                                                setOpenSnackbar(true);
                                            })
                                        }, (response) => {
                                            setSnackBarData({
                                                variant: response.status ? 'success' : 'error',
                                                message: response.msg
                                            });
                                            stopLoader();
                                            setOpenSnackbar(true);
                                        })
                                    }
                                    else {
                                        let updateData = {};
                                        // if (formData.name === editData.name || formData.email === editData.email) {
                                        //     setEditData(null);
                                        //     setStaffFormVisible(false);
                                        // }
                                        if (formData.name !== editData.name) {
                                            updateData.name = formData.name;
                                        }
                                        if (formData.email !== editData.email) {
                                            updateData.email = formData.email;
                                        }
                                        updateStaffDetail(
                                            editData._id,
                                            {
                                            ...updateData
                                        }, (response) => {
                                            setSnackBarData({
                                                variant: response.status ? 'success' : 'error',
                                                message: response.msg
                                            });
                                            getStaffList({
                                                limit: PAGE_LIMITS.staffList,
                                                pageNumber: currentPage,
                                                sortType,
                                                filterType
                                            }, () => {
                                                stopLoader();
                                                setEditData(null);
                                                setStaffFormVisible(false);
                                                setOpenSnackbar(true);
                                            }, (er) => {
                                                stopLoader();
                                                setEditData(null);
                                                setStaffFormVisible(false);
                                                setOpenSnackbar(true);
                                            })
                                        }, (response) => {
                                            setSnackBarData({
                                                variant: response.status ? 'success' : 'error',
                                                message: response.msg
                                            });
                                            stopLoader();
                                            setOpenSnackbar(true);
                                        })
                                    }
                                }}
                                onCancel={() => {
                                    setEditData(null);
                                    setStaffFormVisible(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>}
            <>
                <SnackbarWrapper
                    visible={openSnackBar}
                    onClose={() => setOpenSnackbar(false)}
                    variant={snackbarData.variant}
                    message={snackbarData.message}
                />
                <DecisionPopup
                    modalVisibility={popupVisible}
                    dialogContent={STRINGS.DELETE_STAFF_CONTENT}
                    dialogTitle={STRINGS.DELETE_STAFF_MEMBER}
                    confirmButtonTitle={STRINGS.CONFIRM}
                    rejectButtonTitle={STRINGS.CANCEL}
                    toggleDialogModal={() => setPopVisible(!popupVisible)}
                    onConfirmation={() => {
                        deleteStaff(targetId,
                            (response) => {
                                setPopVisible(false);
                                getStaffList({
                                    limit: PAGE_LIMITS.staffList,
                                    pageNumber: currentPage,
                                    sortType,
                                    filterType
                                }, () => {
                                    setSnackBarData({
                                        variant: response.status ? 'success' : 'error',
                                        message: response.msg
                                    });
                                    stopLoader();
                                    setOpenSnackbar(true);
                                }, (er) => {
                                    setSnackBarData({
                                        variant: response.status ? 'success' : 'error',
                                        message: response.msg
                                    });
                                    stopLoader();
                                    setOpenSnackbar(true);
                                });
                            }, (response) => {
                                setSnackBarData({
                                    variant: response.status ? 'success' : 'error',
                                    message: response.msg
                                });
                                setOpenSnackbar(true)
                            })
                    }}
                    onRejection={() => setPopVisible(false)}
                />
                <div className="container-fluid">

                    <div className="card-header">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className='table-search'>
                                    <TextField
                                        className='text-field'
                                        label={LABELS.search}
                                        type="text"
                                        value={searchString}
                                        placeholder={STRINGS.SEARCH}
                                        onChange={e => {
                                            getStaffList({
                                                limit: PAGE_LIMITS.staffList,
                                                pageNumber: currentPage,
                                                sortType,
                                                searchString: e.target.value,
                                                filterType
                                            }, () => {
                                                    stopLoader()
                                                console.log('success')
                                            }, (er) => {
                                                console.log('failure', er)
                                            })

                                            if (e.target.value === '') {
                                                setCurrentPage(lastActivePage);
                                                setSearchString(e.target.value);
                                            }
                                            else {
                                                setCurrentPage(1);
                                                setSearchString((e.target.value));
                                            }
                                        }}
                                        margin="none"
                                    />
                                    <i><img className='search-icon' src={SEARCH_ICON} alt={''} /></i>
                                </div>
                            </div>
                            <div className="col-sm-8 text-md-right">
                                <button className={'btn btn-md btn-primary'} onClick={() => { setStaffFormVisible(true) }}>{STRINGS.BUTTON_ADD_STAFF}</button>
                            </div>
                        </div>
                    </div>

                    <CustomTable
                        rows={staffList&&staffList.data?staffList.data:[]}
                        rowsPerPage={10}
                        showProfile={false}
                        columns={LAYOUTS.ecrStaff}
                        activePage={1}
                        sortByKey={sortKey}
                        sortType={sortType}
                        EDIT_ICON={EDIT_ICON}
                        DELETE_ICON={DELETE_ICON}
                        onEditClick={data => {
                            setEditData(data);
                            setStaffFormVisible(true);
                        }}
                        onDeleteClick={id => {
                            setTargetId(id);
                            setPopVisible(true);
                        }}
                        emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                        setSortKey={setSortKey}
                    />
                    {staffList&&(staffList.totalDocuments>PAGE_LIMITS.staffList)&&<CustomPagination
                        limit={PAGE_LIMITS.staffList}
                        currentPage={currentPage}
                        itemsCount={staffList&&staffList.data?staffList.data.length:0}
                        totalPages={staffList.totalDocuments}
                        onPageChange={(page) => {
                            setCurrentPage(page.selected + 1);
                            setLastActivePage(page.selected + 1);
                            getStaffList({
                                limit: PAGE_LIMITS.staffList,
                                pageNumber: page.selected + 1,
                                sortType,
                                filterType
                            }, () => {
                                    stopLoader()
                                console.log('success')
                            }, (er) => {
                                console.log('failure', er)
                            })
                        }}
                    />}
                </div>
            </>
        </React.Fragment>
    );
}