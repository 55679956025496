import React from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
import { ROLE_TYPES, } from '../../shared/constants';
import { setAuthorization, stopLoader, setPlatformType } from '../../redux/actions';
import AdminRouter from '../admin_router';
import StaffRouter from '../staff_router';

// const useStyles = makeStyles(theme => ({
//     root: {
//         display: 'flex',
//     },
//     paper: {
//         marginRight: theme.spacing(2),
//     },
// }));
const AuthenticatedRouter = ({
  platformType,
}) => {
    
    return (
      <>
        {platformType===ROLE_TYPES.admin?(<AdminRouter/>):(<StaffRouter/>)}
        </>
    );
}

const mapStateToProps = (state) => {
  return ({
    userToken: state.CommonReducer.userToken,
    platformType: state.CommonReducer.platformType,
  });
}
const mapDispatchToProps = (dispatch) => {
  return {
    setAuthorization: (userData) => dispatch(setAuthorization(userData)),
    setPlatformType: (platformType) => dispatch(setPlatformType(platformType)),
    stopLoader: () => dispatch(stopLoader()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRouter);