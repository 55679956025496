import React, { useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import {
    CustomTable,
    DecisionPopup,
    CustomPagination
} from '../../components/atoms';
import {
    LABELS,
    PAGE_TITLES,
    PAGE_LIMITS,
    LAYOUTS,
    STRINGS,
    TABLE_ARROW_DOWN,
} from '../../shared/constants';
import { AddPromoForm } from './form';
import { SnackbarWrapper } from '../../components/molecules';

export const Screen = ({
    promoList,
    addPromo,
    resetForm,
    stopLoader,
    getPromoList,
    updatePromoCode,
    deletePromoCode
}) => {
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [sortKey, setSortKey] = useState('name');
    const sortType = 1;
    const [promoFormVisible, setPromoFormVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [targetId, setTargetId] = useState(null);
    const [editData, setEditData] = useState(null);
    const [popupVisible, setPopVisible] = useState(false)
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });

    useEffect(() => {
        getPromoList({
            limit: PAGE_LIMITS.staffList,
            pageNumber: currentPage,
            sortType,
        }, () => {
                stopLoader();
            console.log('success')
        }, (er) => {
            console.log('failure', er)
        })
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.profile}</title>
            </Helmet>
            <div className={'container-fluid'}>
                {promoFormVisible && <div className='dropzone-div'>
                <div className="overlay"></div>
                <div className="dropzone-dialog">
                    <div className="dropzone-content">
                        <div className="dropzone-body">
                            <h3 className="mb-4 text-center">{LABELS.addUser}</h3>
                            <AddPromoForm
                                editData={editData}
                                onSubmit={(formData) => {
                                    if (!editData) {
                                        addPromo({
                                            ...formData,
                                            isActive: true
                                        }, (response) => {
                                            setSnackBarData({
                                                variant: response.status ? 'success' : 'error',
                                                message: 'Promocode added successfully.'
                                            });
                                            getPromoList({
                                                limit: PAGE_LIMITS.staffList,
                                                pageNumber: currentPage,
                                                sortType,
                                            }, () => {
                                                stopLoader();
                                                setPromoFormVisible(false);
                                                setOpenSnackbar(true);
                                            }, (er) => {
                                                stopLoader();
                                                setPromoFormVisible(false);
                                                setOpenSnackbar(true);
                                            })
                                        }, (response) => {
                                            setSnackBarData({
                                                variant: response.status ? 'success' : 'error',
                                                message: response.msg
                                            });
                                            stopLoader();
                                            setOpenSnackbar(true);
                                        })
                                    }
                                    else {
                                        let updateData = {};
                                        if (formData.code !== editData.code) {
                                            updateData.code = formData.code;
                                        }
                                        if (formData.value !== editData.value) {
                                            updateData.value = formData.value;
                                        }
                                        if (formData.description !== editData.description) {
                                            updateData.description = formData.description;
                                        }
                                        updatePromoCode(
                                            editData._id,
                                            {
                                                ...updateData,
                                                isActive:editData.isActive
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                getPromoList({
                                                    limit: PAGE_LIMITS.staffList,
                                                    pageNumber: currentPage,
                                                    sortType,
                                                }, () => {
                                                    stopLoader();
                                                    setEditData(null);
                                                    setPromoFormVisible(false);
                                                    setOpenSnackbar(true);
                                                }, (er) => {
                                                    stopLoader();
                                                    setEditData(null);
                                                    setPromoFormVisible(false);
                                                    setOpenSnackbar(true);
                                                })
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                stopLoader();
                                                setOpenSnackbar(true);
                                            });
                                    }
                                }}
                                    onCancel={() => {
                                        setEditData(null);
                                    setPromoFormVisible(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
                </div>}
                <SnackbarWrapper
                    visible={openSnackBar}
                    onClose={() => setOpenSnackbar(false)}
                    variant={snackbarData.variant}
                    message={snackbarData.message}
                />
                <DecisionPopup
                    modalVisibility={popupVisible}
                    dialogContent={STRINGS.DELETE_PROMO_CONTENT}
                    dialogTitle={STRINGS.DELETE_PROMOCODE}
                    confirmButtonTitle={STRINGS.CONFIRM}
                    rejectButtonTitle={STRINGS.CANCEL}
                    toggleDialogModal={() => setPopVisible(!popupVisible)}
                    onConfirmation={() => {
                        deletePromoCode(targetId,
                            (response) => {
                                setPopVisible(false);
                                getPromoList({
                                limit: PAGE_LIMITS.staffList,
                                pageNumber: currentPage,
                                sortType,
                            }, () => {
                                    setSnackBarData({
                                        variant: response.status ? 'success' : 'error',
                                        message: response.msg
                                    });
                                    stopLoader();
                                    setOpenSnackbar(true);
                                }, (er) => {
                                    setSnackBarData({
                                        variant: response.status ? 'success' : 'error',
                                        message: response.msg
                                    });
                                    stopLoader();
                                    setOpenSnackbar(true);
                                });
                            }, (response) => {
                                setSnackBarData({
                                    variant: response.status ? 'success' : 'error',
                                    message: response.msg
                                });
                                setOpenSnackbar(true)
                            })
                    }}
                    onRejection={() => setPopVisible(false)}
                />
                <div>
                    <div className="card-header">
                        <div className="row">
                            <div className="col-sm-4">
                                
                            </div>
                            <div className="col-sm-8 text-md-right">
                                <button className={'btn btn-md btn-primary'} onClick={() => setPromoFormVisible(true)}>{STRINGS.ADD_PROMO_CODE}</button>
                            </div>
                        </div>
                    </div>
                    <CustomTable
                        rows={promoList&&promoList.data?promoList.data:[]}
                        rowsPerPage={10}
                        showProfile={false}
                        columns={LAYOUTS.promoList}
                        activePage={1}
                        sortByKey={sortKey}
                        sortType={sortType}
                        onEditClick={data => {
                            setEditData(data);
                            setPromoFormVisible(true);
                        }}
                        onDeleteClick={id => {
                            setTargetId(id);
                            setPopVisible(true);
                        }}
                        onStatusChange={(checked,id) => {
                            updatePromoCode(
                                            id,
                                            {
                                                isActive:checked
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                getPromoList({
                                                    limit: PAGE_LIMITS.staffList,
                                                    pageNumber: currentPage,
                                                    sortType,
                                                }, () => {
                                                    stopLoader();
                                                    setEditData(null);
                                                    setPromoFormVisible(false);
                                                    setOpenSnackbar(true);
                                                }, (er) => {
                                                    stopLoader();
                                                    setEditData(null);
                                                    setPromoFormVisible(false);
                                                    setOpenSnackbar(true);
                                                })
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                stopLoader();
                                                setOpenSnackbar(true);
                                            });
                        }}
                        emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                        setSortKey={setSortKey}
                    />
                    {promoList&&(promoList.totalDocuments>PAGE_LIMITS.promoList)&&<CustomPagination
                        limit={PAGE_LIMITS.promoList}
                        currentPage={currentPage}
                        itemsCount={promoList&&promoList.data?promoList.data.length:0}
                        totalPages={promoList.totalDocuments}
                        onPageChange={(page) => {
                            setCurrentPage(page.selected + 1);                         
                        }}
                    />}
                </div>
                
            </div>
        </React.Fragment>
    );
}