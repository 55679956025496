import { takeLatest, put, all } from 'redux-saga/effects';
import {
    GET_DRIVER_LIST,
    FETCH_DRIVER_DETAILS,
    FETCH_DRIVER_RATING,
    FETCH_DRIVER_UPCOMING_TRIPS,
    FETCH_DRIVER_PAST_TRIPS,
    DELETE_DRIVER_DETAILS,
    CHARGE_USER_DRIVER,
    startLoader,
    stopLoader,
    setDriverList,
    setDriverDetails,
    setAuthorization,
    setPlatformType,
    setDriverRating,
    setDriverUpcomingTrips,
    setDriverPastTrips,
} from '../actions';
import { STATUS_CODE } from '../../shared/constants';
import {
    getRequest,postRequest,putRequest
} from '../../helpers';
import api  from '../../shared/api/api.js';

function* getEcrDriverList({ data, success, failure }) {
    try {
        let { limit, sortType, searchString, filterType,pageNumber } = data;
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.DRIVER}?limit=${limit}&pageNumber=${pageNumber}&sortType=${sortType}&filterType=${filterType}${!!searchString?`&searchString=${searchString}`:''}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setDriverList(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* getEcrDriverProfile({ driverId,data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.DRIVER_PROFILE}?driverId=${driverId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setDriverDetails(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};

function* deleteEcrDriverDetails({ data,success, failure }) {
    try {
        yield put(startLoader());
        const response = yield putRequest({ API: `${api.URL.DELETE_DRIVER_OR_AGENCY_DETAILS}`,DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
         if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(stopLoader());
            success(response.data);
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* getEcrDriverRating({ driverId,data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.DRIVER_RATING}?driverId=${driverId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setDriverRating(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* getEcrDriverUpcomingTrips({ driverId,data, success, failure }) {
    try {
         let { limit,index } = data;
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.DRIVER_UPCOMING_TRIPS}?limit=${limit}&index=${index}&driverId=${driverId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setDriverUpcomingTrips(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* getEcrDriverPastTrips({ driverId,data, success, failure }) {
    try {
         let { limit,index } = data;
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.DRIVER_PAST_TRIPS}?limit=${limit}&index=${index}&driverId=${driverId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setDriverPastTrips(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};


function* chargeUserDriver({ data,success, failure }) {
    try {
        yield put(startLoader());
        const response = yield postRequest({ API: `${api.URL.CHARGE_DRIVER}`,DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
         if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(stopLoader());
            success(response.data);
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* DriverSaga() {
    yield all([
        takeLatest(GET_DRIVER_LIST, getEcrDriverList),
        takeLatest(FETCH_DRIVER_DETAILS, getEcrDriverProfile),
        takeLatest(FETCH_DRIVER_RATING, getEcrDriverRating),
        takeLatest(FETCH_DRIVER_UPCOMING_TRIPS, getEcrDriverUpcomingTrips),
        takeLatest(FETCH_DRIVER_PAST_TRIPS, getEcrDriverPastTrips),
        takeLatest(DELETE_DRIVER_DETAILS, deleteEcrDriverDetails),
        takeLatest(CHARGE_USER_DRIVER, chargeUserDriver),
    ]);
};

export default DriverSaga;