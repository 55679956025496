import './App.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import {RootRouter} from './routes/root-router';
const { store, persistor } = require(`./redux/store`);

function App() {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <RootRouter />
      </Provider>
    </PersistGate>
  );
}

export default App;
