import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { onSubmitFail } from '../../helpers';
import { Form, InputSubmit, Input } from '../../components/atoms';
import { STRINGS } from '../../shared/constants';
import { connect } from 'react-redux';
import validator from "./validator";


const PriceForm = ({
    editData,
    handleSubmit = () => { },
    initialize,
    credentials = {
        email: '',
        password: ''
    },
    onCancel = () => { },
    onSubmit = () => { }
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.AMOUNT_COL_ID}
                    component={Input}
                    label={STRINGS.AMOUNT}
                    placeholder={STRINGS.AMOUNT_PLACEHOLDER}
                    type={"number"}
                      config={{
                     min:0
                }}
           />
      </div>
        <div className="row">
          <Field
            name={STRINGS.DISCOUNT_COL_ID}
            component={Input}
            label={STRINGS.DISCOUNT_VALUE}
            placeholder={STRINGS.DISCOUNT_PLACEHOLDER}
            type={"number"}
            config={{
              min:0
            }}
          />
        </div>

            <div className="row">
                <div className="col-12 text-center">
                    <button type={"button"} className={"btn btn-md reject-button mr-2"} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                    <InputSubmit buttonLabel={STRINGS.BUTTON_UPDATE_AMOUNT} />
                </div>
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    let initialValues = {
        'amount': props.editData && props.editData.amount ? props.editData.amount : '',
        'discountValue':props.editData && props.editData.discountValue ? props.editData.discountValue : '',
    };

    return {
        initialValues: initialValues,
    };
}

const reduxFormFunction = reduxForm({
    form: "login",
    fields: ['email', 'password'],
    onSubmitFail,
    validate: validator,
    enableReinitialize: true
})(PriceForm);

export const UpdatePriceForm = connect(mapStateToProps, null)(reduxFormFunction);