import {
  SET_DRIVER_LIST,
  SET_DRIVER_LIST_PAGE,
  SET_DRIVER_DETAILS,
  SET_DRIVER_UPCOMING_TRIPS,
  SET_DRIVER_RATING,
  SET_DRIVER_PAST_TRIPS,
} from "../actions";

const initialDriverState = {
  driverList: [],
  driverListPage: 1,
  driverProflie: {},
  driverRatings: [],
  driverUpcomingTrips: [],
  driverPastTrips: [],
};

const DriverReducer = (state = { ...initialDriverState }, action) => {
    switch (action.type) {
        case SET_DRIVER_LIST:
            return {
                ...state,
                driverList: action.data
            };
        case SET_DRIVER_LIST_PAGE:
            return {
                ...state,
                driverListPage:action.page
            }
        case SET_DRIVER_DETAILS:
            return {
                ...state,
                driverProfile:action.data.data
            }
        case SET_DRIVER_RATING:
            return {
                ...state,
                driverRatings:action.data.data
            }
        case SET_DRIVER_UPCOMING_TRIPS:
            return {
                ...state,
               driverUpcomingTrips:action.data.data
            }
        case SET_DRIVER_PAST_TRIPS:
            return {
                ...state,
                driverPastTrips:action.data.data
            }
        default:
            return state;
    };
};

export default DriverReducer;