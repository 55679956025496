import { connect } from 'react-redux';
import { Screen } from "./screen";
import { forgotPassword } from '../../redux/actions';

const mapStateToProps = (state) => {
    return ({
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        forgotPassword: (data, success, failure) => dispatch(forgotPassword(data, success, failure))
    }
}
export const ForgotScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);



