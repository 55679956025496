import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { onSubmitFail } from '../../helpers';
import { Form, InputSubmit, Input } from '../../components/atoms';
import { STRINGS } from '../../shared/constants';
import { connect } from 'react-redux';
import validator from "./validator";


const PromotionForm = ({
    editData,
    handleSubmit = () => { },
    initialize,
    credentials = {
        email: '',
        password: ''
    },
    onCancel = () => { },
    onSubmit = () => { }
}) => {
    return (
        <Form onSubmit={
            handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.CODE_NAME}
                    component={Input}
                    label={STRINGS.CODE_LABEL}
                    placeholder={STRINGS.CODE_PLACEHOLDER}
                    type={'text'}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.CODE_VALUE_NAME}
                    component={Input}
                    label={STRINGS.CODE_VALUE_LABEL}
                    placeholder={STRINGS.CODE_VALUE_PLACEHOLDER}                    
                    type={'number'}
                    config={{
                        min:0
                    }}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.CODE_VALUE_DESCRIPTION}
                    component={Input}
                    label={STRINGS.CODE_DESCRIPTION_LABEL}
                    placeholder={STRINGS.CODE_DESCRIPTION_PLACEHOLDER}
                    type={'text'}
                />
            </div>

            <div className="row">
                <div className="col-12 text-center">
                    <button type={'button'} className={'btn btn-md reject-button mr-2'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                    <InputSubmit buttonLabel={editData && editData.code ? STRINGS.BUTTON_EDIT_PROMOCODE:STRINGS.BUTTON_ADD_PROMOCODE} />
                </div>
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    let initialValues = {
        'code': props.editData && props.editData.code ? props.editData.code : '',
        'value': props.editData && props.editData.value ? props.editData.value : '',
        'description': props.editData && props.editData.description ? props.editData.description : '',
    };

    return {
        initialValues: initialValues,
    };
}

const reduxFormFunction = reduxForm({
    form: "login",
    fields: ['email', 'password'],
    onSubmitFail,
    validate: validator,
    enableReinitialize: true
})(PromotionForm);

export const AddPromoForm = connect(mapStateToProps, null)(reduxFormFunction);