import { all, fork } from 'redux-saga/effects';
import AuthSaga from './auth';
import StaffSaga from './staff';
import DriverSaga from './driver';
import AgencySaga from './agency';
import PromotionSaga from './promotion';
import PackageSaga from './package';

function* dataSaga() {
  yield all([
    fork(AuthSaga),
    fork(StaffSaga),
    fork(DriverSaga),
    fork(AgencySaga),
    fork(PackageSaga),
    fork(PromotionSaga),
  ]);
};


export default dataSaga;
