import { takeLatest, put, all } from 'redux-saga/effects';
import {
    GET_PACKAGES,
    UPDATE_PACKAGE,
    startLoader,
    stopLoader,
    setPackagesList,
    setAuthorization,
    setPlatformType,
} from '../actions';
import { STATUS_CODE } from '../../shared/constants';
import {
    getRequest,
    putRequest,
} from '../../helpers';
import api  from '../../shared/api/api.js';

function* getPackageList({ success, failure }) {
    try {
        // let { limit, sortType, searchString, filterType,pageNumber } = data;
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.PACKAGE}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setPackagesList(response.data)); 
            success(response.data); 
        }
    }
    catch (error) {
    }
};


function* updatePackage({ packageId, data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield putRequest({ API: `${api.URL.PACKAGE}/${packageId}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(stopLoader());
            success(response.data);
        }

    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};

function* PackageSaga() {
    yield all([
        takeLatest(GET_PACKAGES, getPackageList),
        takeLatest(UPDATE_PACKAGE, updatePackage),
    ]);
};

export default PackageSaga;