import { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';


class ScrollComponent extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            document.getElementsByTagName('body')[0].scrollTo(0, 0)
            // this.props.savePreviousLocation(prevProps.location.pathname)
        }
    }
    render() {
        return this.props.children || null
    }
}

const mapStateToProps = (state) => {
    return ({
        state
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export const ScrollToTop = withRouter(connect(mapStateToProps, mapDispatchToProps)(ScrollComponent));
