export const GET_DRIVER_LIST = 'GET_DRIVER_LIST';
export const SET_DRIVER_LIST = 'SET_DRIVER_LIST';
export const SET_DRIVER_LIST_PAGE = 'SET_DRIVER_LIST_PAGE';
export const FETCH_DRIVER_DETAILS = 'FETCH_DRIVER_DETAILS';
export const FETCH_DRIVER_RATING = "FETCH_DRIVER_RATING";
export const SET_DRIVER_DETAILS = 'SET_DRIVER_DETAILS';
export const SET_DRIVER_RATING = "SET_DRIVER_RATING";
export const FETCH_DRIVER_UPCOMING_TRIPS = 'FETCH_DRIVER_UPCOMING_TRIPS';
export const SET_DRIVER_UPCOMING_TRIPS = 'SET_DRIVER_UPCOMING_TRIPS';
export const FETCH_DRIVER_PAST_TRIPS = 'FETCH_DRIVER_PAST_TRIPS';
export const SET_DRIVER_PAST_TRIPS = "SET_DRIVER_PAST_TRIPS";
export const DELETE_DRIVER_DETAILS = 'DELETE_DRIVER_DETAILS';
export const CHARGE_USER_DRIVER = 'CHARGE_USER_DRIVER';


export const fetchDriverList = (data, success, failure) => {
    return {
        type: GET_DRIVER_LIST,
        data,
        success,
        failure
    };
};

export const setDriverList = (data, success, failure) => {
    return {
        type: SET_DRIVER_LIST,
        data,
        success,
        failure
    };
};

export const setDriverListPage = page => {
    return {
        type: SET_DRIVER_LIST_PAGE,
        page
    };
};

export const fetchDriverDetails = (driverId, data, success, failure) => {
    return {
        type: FETCH_DRIVER_DETAILS,
        driverId,
        data,
        success,
        failure
    };
};
export const deleteDriverDetails = (data, success, failure) => {
  return {
    type: DELETE_DRIVER_DETAILS,
    data,
    success,
    failure
  }
  
}
export const setDriverDetails = data => {
    return {
        type: SET_DRIVER_DETAILS,
        data
    };
};
export const fetchDriverRating = (driverId, data, success, failure) => {
  return {
    type: FETCH_DRIVER_RATING,
    driverId,
    data,
    success,
    failure,
  };
};

export const setDriverRating = (data) => {
  return {
    type: SET_DRIVER_RATING,
    data,
  };
};

export const fetchDriverUpcomingTrips = (driverId, data, success, failure) => {
    return {
        type: FETCH_DRIVER_UPCOMING_TRIPS,
        driverId,
        data,
        success,
        failure
    };
};
export const setDriverUpcomingTrips = (data) => {
  return {
    type: SET_DRIVER_UPCOMING_TRIPS,
    data,
  };
};
export const fetchDriverPastTrips = (driverId, data, success, failure) => {
    return {
        type: FETCH_DRIVER_PAST_TRIPS,
        driverId,
        data,
        success,
        failure
    };
};
export const setDriverPastTrips = (data) => {
  return {
    type: SET_DRIVER_PAST_TRIPS,
    data,
  };
};

export const chargeUserDriver = (data, success, failure) => {
  return {
    type: CHARGE_USER_DRIVER,
    data,
    success,
    failure
  }
  
}