import React, { useState } from "react";
import { ForgotReduxForm } from './form';
import { Helmet } from 'react-helmet';
import appLogo from '../../assets/img/logo.png';
import "./style.scss";
import { FormWrapper } from '../../components/hoc/form-wrapper';
import { SnackbarWrapper } from '../../components/molecules';
import { InputSubmit } from '../../components/atoms';

import { ROUTES, STRINGS, LABELS, PAGE_TITLES } from '../../shared/constants';


export const Screen = ({
    forgotPassword,
    history
}) => {
    const [emailSent, setEmailSent] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    })
    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.forgotPassword}</title>
            </Helmet>
            <div className={'container'}>
                <div className="front-form">
                    <div className="front_form_group">
                        <div className="header-main text-center">
                            <span className="logo">
                                <img src={appLogo} alt="ECR" className="img-fluid d-inline-block" width="200" />
                            </span>
                        </div>
                        <FormWrapper>
                            <div className="col-md-12">
                                <div className="form_title text-center">
                                    <SnackbarWrapper
                                        visible={openSnackBar}
                                        onClose={() => setOpenSnackbar(false)}
                                        variant={snackbarData.variant}
                                        message={snackbarData.message}
                                    />
                                    {/* <i>
                                <img src={emailSent ? require(`../../../../../../assets/email-sent.png`) : require(`../../../../../../assets/forgot.png`)} alt="" className="img-fluid" width="130px" />
                            </i> */}
                                    <h3>{emailSent ? LABELS.checkYourMail : LABELS.forgotPassword}</h3>
                                    {emailSent ? <p>{STRINGS.EMAIL_RESPONSE_MESSAGE}</p> : <p>{STRINGS.SHARE_EMAIL_MESSAGE}</p>}
                                </div>
                                {!emailSent && <ForgotReduxForm onSubmit={(credentials) => {
                                    forgotPassword({
                                        email: credentials.email,
                                    }, (response) => {
                                        setEmailSent(true);

                                    }, (response) => {
                                        setSnackBarData({
                                            variant: response.status ? 'success' : 'error',
                                            message: response.msg
                                        });
                                        setOpenSnackbar(true)
                                    })
                                }} />}
                                {emailSent &&
                                    <form noValidate onSubmit={(value) => {
                                        history.replace(ROUTES.LOGIN)
                                }}>
                                    <div className='col-md-12 text-center'>
                                        <InputSubmit
                                            buttonLabel={STRINGS.FORGOT_LOGIN}
                                            containerStyle={"text-center"} />
                                    </div>
                                </form>
                                }
                            </div>
                        </FormWrapper>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}