import React, { useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import {
    CustomTable,
    CustomPagination
} from '../../components/atoms';
import {
    LABELS,
    PAGE_TITLES,
    PAGE_LIMITS,
    LAYOUTS,
    STRINGS,
    TABLE_ARROW_DOWN,
} from '../../shared/constants';
import { UpdatePriceForm } from './form';

export const Screen = ({
    packageList={data:[],totalDocuments:0},
    resetForm,
    stopLoader,
    getPackagesList,
    updatePackagePrice,
}) => {
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [sortKey, setSortKey] = useState('name');
    const sortType = 1;
    const [currentPage, setCurrentPage] = useState(1);
    const [editData, setEditData] = useState(null);
    const [priceFormVisible, setPriceFormVisible] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });


    useEffect(() => {
        getPackagesList(() => {
            stopLoader();
            console.log('success')
        }, (er) => {
                stopLoader();
            console.log('failure', er)
        })
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.profile}</title>
            </Helmet>
            <div className={'container-fluid'}>
                {priceFormVisible && <div className='dropzone-div'>
                <div className="overlay"></div>
                <div className="dropzone-dialog">
                    <div className="dropzone-content">
                        <div className="dropzone-body">
                            <h3 className="mb-4 text-center">{LABELS.addUser}</h3>
                            <UpdatePriceForm
                                editData={editData}
                                    onSubmit={(formData) => {
                                        updatePackagePrice(
                                            editData._id,
                                        {
                                            ...formData
                                        }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                getPackagesList(() => {
                                                    stopLoader();
                                                    setEditData(null);
                                                    setPriceFormVisible(false);
                                                    setOpenSnackbar(true);
                                                }, (er) => {
                                                    stopLoader();
                                                    setEditData(null);
                                                    setPriceFormVisible(false);
                                                    setOpenSnackbar(true);
                                                })
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                stopLoader();
                                                setOpenSnackbar(true);
                                            })
                                }}
                                onCancel={() => {
                                    setEditData(null);
                                    setPriceFormVisible(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
                </div>}
                <div>
                    <CustomTable
                        deleteRequired={false}
                        rows={packageList&&packageList.data?packageList.data:[]}
                        rowsPerPage={10}
                        showProfile={false}
                        columns={LAYOUTS.packageList}
                        activePage={1}
                        sortByKey={sortKey}
                        sortType={sortType}
                        onEditClick={data => {
                            setEditData(data);
                            setPriceFormVisible(true);
                        }}
                        emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                        setSortKey={setSortKey}
                    />
                    {packageList&&(packageList.totalDocuments>PAGE_LIMITS.packageList)&&<CustomPagination
                        limit={PAGE_LIMITS.packageList}
                        currentPage={currentPage}
                        itemsCount={packageList&&packageList.data?packageList.data.length:0}
                        totalPages={packageList.totalDocuments}
                        onPageChange={(page) => {
                            setCurrentPage(page.selected + 1);                         
                        }}
                    />}
                </div>
                
            </div>
        </React.Fragment>
    );
}