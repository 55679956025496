export const CHECK_LOGIN = 'CHECK_LOGIN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_AUTHORIZATION = 'SET_AUTHORIZATION';
export const SET_PLATFORM_TYPE = 'SET_PLATFORM_TYPE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export const setAuthorization = (userToken) => {
    return {
        type: SET_AUTHORIZATION,
        userToken
    };
};

export const setPlatformType = (platformType) => {
    return {
        type: SET_PLATFORM_TYPE,
        platformType
    };
};

export const checkLogin = (credentials, success, onError) => {
    return {
        type: CHECK_LOGIN,
        credentials,
        success,
        onError
    };
};

export const logout = (token, success, failure) => {
    return {
        type: LOGOUT_USER,
        token,
        success,
        failure
    };
};

export const setUserData = data => {
    return {
        type: SET_USER_DATA,
        data
    };
};

export const forgotPassword = (data, success, failure) => {
    return {
        type: FORGOT_PASSWORD,
        data,
        success,
        failure
    };
};