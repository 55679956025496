import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {ChangePasswordForm} from './form';
import "./style.scss";
import {
    PAGE_TITLES
} from '../../shared/constants';
import { SnackbarWrapper } from '../../components/molecules';


export const Screen = ({
    resetForm,
    stopLoader,
    updateUserDetails
}) => {
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [openSnackBar, setOpenSnackbar] = useState(false);
    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.profile}</title>
            </Helmet>
            <div className={'container-fluid'}>
                <div className="row">
                    <div className="col-md-6 position-relative">
                        <SnackbarWrapper
                            visible={openSnackBar}
                            onClose={() => setOpenSnackbar(false)}
                            variant={snackbarData.variant}
                            message={snackbarData.message}
                        />
                        <ChangePasswordForm onSubmit={(formData) => {
                            delete formData.confirmPassword;
                            updateUserDetails(
                                { ...formData },
                                (response) => {
                                    resetForm('changePassword');
                                    setSnackBarData({
                                            variant: response.status ? 'success' : 'error',
                                            message: 'Password updated successfully.'
                                        });
                                    stopLoader();
                                    setOpenSnackbar(true);
                                },
                                (response) => {
                                    resetForm('changePassword');
                                    setSnackBarData({
                                        variant: response.status ? 'success' : 'error',
                                        message: response.msg
                                    });
                                    stopLoader();
                                    setOpenSnackbar(true);
                                })
                        }}/>
                    </div>
                </div>                
            </div>
        </React.Fragment>
    );
}