import { SET_PROMO_LIST } from '../actions';

const initialPromotionState = {
    promoList: [],
};

const PromotionReducer = (state = { ...initialPromotionState }, action) => {
    switch (action.type) {
        case SET_PROMO_LIST:
            return {
                ...state,
                promoList: action.data
            };
        default:
            return state;
    };
};

export default PromotionReducer;