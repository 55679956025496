import { takeLatest, all, put } from "redux-saga/effects";
import {
    SET_AUTHORIZATION,
    CHECK_LOGIN,
    FORGOT_PASSWORD,
    startLoader,
    setUserData,
    stopLoader,
    setPlatformType,
    setAuthorization
} from '../actions';
import { STATUS_CODE } from '../../shared/constants';
import api from "../../shared/api/api";
import { updateAuthToken,postRequestNoAuth } from '../../helpers';

function* setUserToken({ userToken }) {
    try {
        yield updateAuthToken(userToken);
    }
    catch (error) {
        return;
    }
}

function* checkLogin({ credentials, success, onError}) {
    try {
        yield put(startLoader())
        const response = yield postRequestNoAuth({ API: `${api.URL.LOGIN}`, DATA: credentials });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            yield put(setPlatformType(null))
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            onError(response.data);
            yield put(stopLoader());
        }
        else {
            yield put(setUserData(response.data.data))
            yield put(setAuthorization(response.data.token));
            yield put(setPlatformType(response.data.data.role));
            success();
            yield put(stopLoader());
        }
    }
    catch (error) {
    }
};

function* forgotPassword({ data, success, failure}) {
    try {
        yield put(startLoader())
        const response = yield postRequestNoAuth({ API: `${api.URL.FORGOT_PASSWORD}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            failure(response.data);
            yield put(setAuthorization(null));
            yield put(setPlatformType(null))
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data);
            yield put(stopLoader());
        }
        else {
            success(response.data);
            yield put(stopLoader());
        }
    }
    catch (error) {
        failure(error)
    };
};

function* AuthSaga() {
    yield all([
        takeLatest(SET_AUTHORIZATION, setUserToken),
        takeLatest(CHECK_LOGIN, checkLogin),
        takeLatest(FORGOT_PASSWORD, forgotPassword),
    ]);
}

export default AuthSaga;
