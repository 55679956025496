import { connect } from 'react-redux';
import { Screen } from "./screen";
import { fetchDriverList, stopLoader,fetchDriverDetails, fetchDriverRating, fetchDriverUpcomingTrips, fetchDriverPastTrips,deleteDriverDetails, chargeUserDriver } from '../../redux/actions';

const mapStateToProps = (state) => {
  return ({
    driverList: state.DriverReducer.driverList,
    driverProfile: state.DriverReducer.driverProfile,
    driverRatings: state.DriverReducer.driverRatings,
    driverUpcomingTrips: state.DriverReducer.driverUpcomingTrips,
    driverPastTrips:state.DriverReducer.driverPastTrips,
  });
}
const mapDispatchToProps = (dispatch) => {
  return {
    stopLoader: () => dispatch(stopLoader()),
    fetchDriverList: (data, success, failure) =>
      dispatch(fetchDriverList(data, success, failure)),
    fetchDriverDetails: (driverId, data, success, failure) =>
      dispatch(fetchDriverDetails(driverId, data, success, failure)),
    fetchDriverRating: (driverId, data, success, failure) =>
      dispatch(fetchDriverRating(driverId, data, success, failure)),
    fetchDriverUpcomingTrips: (driverId, data, success, failure) =>
      dispatch(fetchDriverUpcomingTrips(driverId, data, success, failure)),
    fetchDriverPastTrips: (driverId, data, success, failure) =>
      dispatch(fetchDriverPastTrips(driverId, data, success, failure)),
    deleteDriverDetails: (data, success, failure) =>
      dispatch(deleteDriverDetails(data, success, failure)),
    chargeUserDriver: (data, success, failure) =>
      dispatch(chargeUserDriver(data, success, failure)),
  };
}
export const DriverDetailsScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);