import { connect } from 'react-redux';
import { Screen } from "./screen";
import { fetchDriverList, stopLoader, setDriverListPage,deleteDriverDetails } from '../../redux/actions';

const mapStateToProps = (state) => {
  return ({
    driverList: state.DriverReducer.driverList,
    driverListPage:state.DriverReducer.driverListPage
  });
}
const mapDispatchToProps = (dispatch) => {
  return {
    stopLoader: () => dispatch(stopLoader()),
    fetchDriverList: (data, success, failure) =>
      dispatch(fetchDriverList(data, success, failure)),
    setDriverListPage: (page) => dispatch(setDriverListPage(page)),
    deleteDriverDetails: (data, success, failure) =>
      dispatch(deleteDriverDetails(data, success, failure)),
  };
}
export const DriverScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);