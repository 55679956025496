export const GET_AGENCY_LIST = 'GET_AGENCY_LIST';
export const GET_AGENCY_TOKENS = 'GET_AGENCY_TOKENS';
export const SET_AGENCY_LIST = 'SET_AGENCY_LIST';
export const SET_AGENCY_LIST_PAGE = "SET_AGENCY_LIST_PAGE";
export const SET_AGENCY_TOKENS = 'SET_AGENCY_TOKENS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const DELETE_AGENCY_DETAILS = "DELETE_AGENCY_DETAILS";
export const GET_AGENCY_PROFILE = 'GET_AGENCY_PROFILE';
export const SET_AGENCY_PROFILE = 'SET_AGENCY_PROFILE';
export const FETCH_AGENCY_UPCOMING_TRIPS = "FETCH_AGENCY_UPCOMING_TRIPS";
export const SET_AGENCY_UPCOMING_TRIPS = "SET_AGENCY_UPCOMING_TRIPS";
export const FETCH_AGENCY_PAST_TRIPS = "FETCH_AGENCY_PAST_TRIPS";
export const SET_AGENCY_PAST_TRIPS = "SET_AGENCY_PAST_TRIPS";

export const fetchAgencyList = (data, success, failure) => {
    return {
        type: GET_AGENCY_LIST,
        data,
        success,
        failure
    };
};

export const fetchAgencyTokens = (agencyId,data, success, failure) => {
  return {
    type: GET_AGENCY_TOKENS,
    agencyId,
    data,
    success,
    failure,
  };
};

export const fetchAgencyProfile = (agencyId, data, success, failure) => {
  return {
    type: GET_AGENCY_PROFILE,
    agencyId,
    data,
    success,
    failure,
  };
};
export const setAgencyProfile = (data) => {
  return {
    type: SET_AGENCY_PROFILE,
    data,
  };
};
export const setAgencyList = (data, success, failure) => {
    return {
        type: SET_AGENCY_LIST,
        data,
        success,
        failure
    };
};
export const setAgencyTokens = (data, success, failure) => {
  return {
    type: SET_AGENCY_TOKENS,
    data,
    success,
    failure
  }
}
export const fetchAgencyUpcomingTrips = (agencyId, data, success, failure) => {
  return {
    type: FETCH_AGENCY_UPCOMING_TRIPS,
    agencyId,
    data,
    success,
    failure,
  };
};
export const setAgencyUpcomingTrips = (data) => {
  return {
    type: SET_AGENCY_UPCOMING_TRIPS,
    data,
  };
};
export const fetchAgencyPastTrips = (agencyId, data, success, failure) => {
  return {
    type: FETCH_AGENCY_PAST_TRIPS,
    agencyId,
    data,
    success,
    failure,
  };
};
export const setAgencyPastTrips = (data) => {
  return {
    type: SET_AGENCY_PAST_TRIPS,
    data,
  };
};
export const updateAgencyStatus = (agencyId, data, success, failure) => {
  return {
    type: UPDATE_STATUS,
    agencyId,
    data,
    success,
    failure,
  };
    };
    export const deleteAgencyDetails = (data, success, failure) => {
      return {
        type: DELETE_AGENCY_DETAILS,
        data,
        success,
        failure,
      };
    };
export const setAgencyListPage = (page) => {
  return {
    type: SET_AGENCY_LIST_PAGE,
    page,
  };
};
