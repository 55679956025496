import React, { useEffect,useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthenticatedRouter from '../authenticated_router';
import AuthRouter from '../auth_router';
import { Loader } from '../../components/atoms/loader';

export const MainRouter = (props) => {
    const { userToken, loader } = props;
    const [token,setToken]=useState(null)

    useEffect(() => {
        setToken(userToken)
    },[userToken])

    return (
        <BrowserRouter>
                    {/* <ScrollToTop> */}
                        {loader && <Loader />}
                        
                    {token ?
                        (<AuthenticatedRouter {...props} />)
                                : (<AuthRouter {...props} />)
                        }

                {/* </ScrollToTop> */}
                </BrowserRouter>
    );
}

const mapStateToProps = (state) => {
    return {
      userToken: state.CommonReducer.userToken,
      loader: state.CommonReducer.loader,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export const RootRouter = connect(mapStateToProps, mapDispatchToProps)(MainRouter);