import { SET_PACKAGES } from '../actions';

const initialPackageState = {
    packageList: [],
};

const PackageReducer = (state = { ...initialPackageState }, action) => {
    switch (action.type) {
        case SET_PACKAGES:
            return {
                ...state,
                packageList: action.data
            };
        default:
            return state;
    };
};

export default PackageReducer;