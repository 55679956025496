import { connect } from 'react-redux';
import { Screen } from "./screen";
import { reset } from 'redux-form';
import { stopLoader,getPackagesList,updatePackagePrice } from '../../redux/actions';

const mapStateToProps = (state) => {
  return ({
    packageList:state.PackageReducer.packageList
  });
}
const mapDispatchToProps = (dispatch) => {
  return {
    stopLoader: () => dispatch(stopLoader()),
    resetForm:(formName)=>dispatch(reset(formName)),
    getPackagesList: (success, failure) => dispatch(getPackagesList(success, failure)),
    updatePackagePrice: (packageId, data, success, failure) => {
      dispatch(updatePackagePrice(packageId, data, success, failure))
    }
  }
}
export const PriceSettingScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);