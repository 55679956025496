import { connect } from 'react-redux';
import { Screen } from "./screen";
import { getStaffList, stopLoader,deleteStaff,addEcrStaff,updateStaffDetail } from '../../redux/actions';

const mapStateToProps = (state) => {
  return ({
    staffList:state.StaffReducer.staffList
  });
}
const mapDispatchToProps = (dispatch) => {
  return {
    stopLoader:()=>dispatch(stopLoader()),
    getStaffList:(data,success,failure)=>dispatch(getStaffList(data,success,failure)),
    addEcrStaff:(data,success,failure)=>dispatch(addEcrStaff(data,success,failure)),
    deleteStaff:(staffId,success,failure)=>dispatch(deleteStaff(staffId,success,failure)),
    updateStaffDetail:(staffId,data,success,failure)=>dispatch(updateStaffDetail(staffId,data,success,failure)),
  }
}
export const EcrStaffScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);



