import React from 'react';
import { Route, Switch,Redirect } from 'react-router-dom';
import { ROUTES } from '../../shared/constants';
import { LoginScreen } from '../../views/login';
import { ForgotScreen } from '../../views/forgot-password';

const AuthRouter = (props) => {

    return (
        <React.Fragment>
            <Switch>
                <Route path={ROUTES.LOGIN} render={() => { return <LoginScreen /> }} />
                <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotScreen} />
                <Redirect to={ROUTES.LOGIN} />
            </Switch>
        </React.Fragment>
    );
}

export default (AuthRouter);