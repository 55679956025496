export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

export const startLoader = () => {
    return {
        type: START_LOADER,
    };
};

export const stopLoader = () => {
    return {
        type: STOP_LOADER,
    };
};

export const updateUserDetails = (data, success, failure) => {
    return {
        type: UPDATE_USER_DETAILS,
        data,
        success,
        failure
    };
};