import { takeLatest, put, all } from 'redux-saga/effects';
import {
    GET_PROMO_LIST,
    ADD_NEW_PROMO,
    UPDATE_PROMO,
    DELETE_PROMO_CODE,
    startLoader,
    stopLoader,
    setPromoList,
    setAuthorization,
    setPlatformType,
} from '../actions';
import { STATUS_CODE } from '../../shared/constants';
import {
    getRequest,
    putRequest,
    postRequest,
    deleteRequest
} from '../../helpers';
import api  from '../../shared/api/api.js';

function* getPromoList({ data, success, failure }) {
    try {
        let { limit, sortType, searchString,pageNumber } = data;
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.PROMOTION}?limit=${limit}&pageNumber=${pageNumber}&sortType=${sortType}${!!searchString?`&searchString=${searchString}`:''}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            success(response.data);
            yield put(setPromoList(response.data))
        }
    }
    catch (error) {
    }
};

function* addNewPromo({ data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield postRequest({ API: `${api.URL.PROMOTION}`, DATA: data  });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            success(response.data);
        }
    }
    catch (error) {
    }
};

function* updatePromo({ promoId, data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield putRequest({ API: `${api.URL.PROMOTION}/${promoId}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(stopLoader());
            success(response.data);
        }

    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};

function* deletePromo({ promoId, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield deleteRequest({ API: `${api.URL.PROMOTION}/${promoId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            success(response.data);
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};

function* PromotionSaga() {
    yield all([
        takeLatest(GET_PROMO_LIST, getPromoList),
        takeLatest(ADD_NEW_PROMO, addNewPromo),
        takeLatest(UPDATE_PROMO, updatePromo),
        takeLatest(DELETE_PROMO_CODE, deletePromo),
    ]);
};

export default PromotionSaga;