import { SET_AGENCY_LIST,SET_AGENCY_LIST_PAGE,SET_AGENCY_TOKENS,SET_AGENCY_PROFILE,SET_AGENCY_UPCOMING_TRIPS,SET_AGENCY_PAST_TRIPS} from '../actions';

const initialAgencyState = {
    agencyList: [],
  agencyListPage: 1,
  agencyTokens: 0,
    agencyProfile:{},
  agencyUpcomingTrips: [],
  agencyPastTrips: [],
};

const AgencyReducer = (state = { ...initialAgencyState }, action) => {
    switch (action.type) {
      case SET_AGENCY_LIST:
        return {
          ...state,
          agencyList: action.data,
        };
      case SET_AGENCY_LIST_PAGE:
        return {
          ...state,
          agencyListPage: action.page,
        };
      case SET_AGENCY_TOKENS:
        return {
          ...state,
          agencyTokens: action.data,
        };
      case SET_AGENCY_PROFILE:
        return {
          ...state,
          agencyProfile: action.data.data,
        };
      case SET_AGENCY_UPCOMING_TRIPS:
        return {
          ...state,
          agencyUpcomingTrips: action.data.data,
        };
      case SET_AGENCY_PAST_TRIPS:
        return {
          ...state,
          agencyPastTrips: action.data.data,
        };
      default:
        return state;
    };
};

export default AgencyReducer;