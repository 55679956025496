import { STRINGS,VALIDATION_MESSAGES } from '../../shared/constants';

const validator = values => {
  const errors = {};
  if (!values[STRINGS.OLDPASSWORD_INPUT_NAME]) {
    errors[STRINGS.OLDPASSWORD_INPUT_NAME] =
      VALIDATION_MESSAGES.EXISTING_PASSWORD_REQUIRED;
  }
  if (values[STRINGS.OLDPASSWORD_INPUT_NAME] && values[STRINGS.OLDPASSWORD_INPUT_NAME].length < 6) {
    errors[STRINGS.OLDPASSWORD_INPUT_NAME] =
      VALIDATION_MESSAGES.PASSWORD_LENGTH_VALIDATION;
  }
  if (!values[STRINGS.NEWPASSWORD_INPUT_NAME]) {
    errors[STRINGS.NEWPASSWORD_INPUT_NAME] =
      VALIDATION_MESSAGES.NEW_PASSWORD_REQUIRED;
  }
  if (!values[STRINGS.CONFIRM_PASSWORD_INPUT_NAME]||(values[STRINGS.NEWPASSWORD_INPUT_NAME]&&!values[STRINGS.CONFIRM_PASSWORD_INPUT_NAME])) {
    errors[STRINGS.CONFIRM_PASSWORD_INPUT_NAME] =
      VALIDATION_MESSAGES.RE_ENTER_YOUR_NEW_PASSWORD;
  }
  if (values[STRINGS.NEWPASSWORD_INPUT_NAME] && values[STRINGS.NEWPASSWORD_INPUT_NAME].length < 6) {
    errors[STRINGS.NEWPASSWORD_INPUT_NAME] =
      VALIDATION_MESSAGES.PASSWORD_LENGTH_VALIDATION;
  }
  if (values[STRINGS.CONFIRM_PASSWORD_INPUT_NAME]!==values[STRINGS.NEWPASSWORD_INPUT_NAME]) {
    errors[STRINGS.CONFIRM_PASSWORD_INPUT_NAME] =
      VALIDATION_MESSAGES.PASSWORDS_DO_NOT_MATCH;
  }
  if (values[STRINGS.CONFIRM_PASSWORD_INPUT_NAME] && values[STRINGS.CONFIRM_PASSWORD_INPUT_NAME].length < 6) {
    errors[STRINGS.CONFIRM_PASSWORD_INPUT_NAME] =
      VALIDATION_MESSAGES.PASSWORD_LENGTH_VALIDATION;
  }

  return errors;
};

export default validator;
