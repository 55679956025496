import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import {
  ROUTES,
    LABELS,
    PAGE_TITLES,
    PAGE_LIMITS,
    STRINGS,
    TABLE_ARROW_DOWN,
    LAYOUTS,
    SEARCH_ICON
} from '../../shared/constants';
import CsvDownloader from 'react-csv-downloader';
import {
    CustomTable,
  CustomPagination,
    DecisionPopup
} from '../../components/atoms';
import { TextField } from '@material-ui/core';
// import { fetchAgencyTokens } from "../../redux/actions";
import { SnackbarWrapper } from "../../components/molecules";


export const Screen = ({
  history,
  agencyList,
  fetchAgencyList,
  agencyListPage,
  stopLoader,
  setAgencyListPage,
  deleteAgencyDetails,
  updateAgencyStatus
}) => {
    const [openSnackBar, setOpenSnackbar] = useState(false);
  const [deletePopupVisible, setDeletePopVisible] = useState(false);
  const [approvePopupVisible, setApprovePopVisible] = useState(false);
  const [rejectPopupVisible, setRejectPopVisible] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [sortKey, setSortKey] = useState('name');
  const sortType = 1;
  const [agencyApproveId, setAgencyApproveId] = useState(null);
  const [agencyRejectId, setAgencyRejectId] = useState(null);
  const [targetId, setTargetId] = useState(null);
    const [currentPage, setCurrentPage] = useState(agencyListPage);
    const filterType = 1;
    // const [emailFormVisible, setEmailFormVisible] = useState(false);
    const [lastActivePage, setLastActivePage] = useState(agencyListPage);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });

    useEffect(() => {
      fetchAgencyList(
        {
          limit: PAGE_LIMITS.agencyList,
          pageNumber: currentPage,
          sortType,
          filterType,
        },
        () => {
          stopLoader()
          console.log("success");
        },
        (er) => {
          console.log("failure", er);
        }
      );
    }, []);
    return (
      <React.Fragment>
        <Helmet>
          <title>{PAGE_TITLES.agencies}</title>
        </Helmet>
        <div className="container-fluid">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-4">
                <div className="table-search">
                  <TextField
                    className="text-field"
                    label={LABELS.search}
                    type="text"
                    value={searchString}
                    placeholder={STRINGS.SEARCH}
                    onChange={(e) => {
                      fetchAgencyList(
                        {
                          limit: PAGE_LIMITS.agencyList,
                          pageNumber: currentPage,
                          sortType,
                          searchString: e.target.value,
                          filterType,
                        },
                        () => {
                          stopLoader();
                          console.log("success");
                        },
                        (er) => {
                          console.log("failure", er);
                        }
                      );

                      if (e.target.value === "") {
                        setCurrentPage(lastActivePage);
                        setSearchString(e.target.value);
                      } else {
                        setCurrentPage(1);
                        setSearchString(e.target.value);
                      }
                    }}
                    margin="none"
                  />
                  <i>
                    <img className="search-icon" src={SEARCH_ICON} alt={""} />
                  </i>
                </div>
              </div>
              <div className="col-sm-8 text-md-right">
                {/* <button className={'btn btn-md btn-primary'} onClick={() => setEmailFormVisible(true)}>{STRINGS.EMAIL_AGENCIES}</button> */}
                <CsvDownloader
                  className={"csv-downloader"}
                  filename={"Agencies details list"}
                  separator={","}
                  datas={agencyList && agencyList.data ? agencyList.data : []}
                >
                  <button className={"btn btn-md btn-primary ml-2"}>
                    Download CSV
                  </button>
                </CsvDownloader>
              </div>
            </div>
          </div>

          {/* {emailFormVisible&&<div className='dropzone-div'>
                            <div className="overlay"></div>
                            <div className="dropzone-dialog">
                                <div className="dropzone-content">
                                    <div className="dropzone-body">
                                     <Editor /> 
                                    <button className={'btn btn-md reject-button mr-2'} onClick={() => setEmailFormVisible(false)}>{STRINGS.CANCEL}</button>
                                    <button className={'btn btn-md btn-primary'} onClick={() => {}}>{STRINGS.SEND_EMAIL}</button>
                                </div>
                            </div>
                        </div>
                    </div>} */}
          <SnackbarWrapper
            visible={openSnackBar}
            onClose={() => setOpenSnackbar(false)}
            variant={snackbarData.variant}
            message={snackbarData.message}
          />
          <DecisionPopup
            modalVisibility={deletePopupVisible}
            dialogContent={STRINGS.DELETE_CONTENT_AGENCY}
            dialogTitle={STRINGS.DELETE_AGENCY}
            confirmButtonTitle={STRINGS.CONFIRM}
            rejectButtonTitle={STRINGS.CANCEL}
            toggleDialogModal={() => setDeletePopVisible(!deletePopupVisible)}
            onConfirmation={() => {
              deleteAgencyDetails(
                {
                  agencyId: targetId,
                },
                (response) => {
                  setDeletePopVisible(false);
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg,
                  });
                  fetchAgencyList(
                    {
                      limit: PAGE_LIMITS.agencyList,
                      pageNumber: currentPage,
                      sortType,
                      filterType,
                    },
                    () => {
                      stopLoader();
                      console.log("success");
                    },
                    (er) => {
                      console.log("failure", er);
                    }
                  );
                },
                (response) => {
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg,
                  });
                  stopLoader();
                  setOpenSnackbar(true);
                }
              );
            }}
            onRejection={() => setDeletePopVisible(false)}
          />
          <DecisionPopup
            modalVisibility={approvePopupVisible}
            dialogContent={STRINGS.APPROVE_AGENCY_CONTENT}
            dialogTitle={STRINGS.APPROVE_AGENCY}
            confirmButtonTitle={STRINGS.CONFIRM}
            rejectButtonTitle={"STRINGS.CANCEL"}
            toggleDialogModal={() => setApprovePopVisible(!approvePopupVisible)}
            onConfirmation={() => {
              updateAgencyStatus(
                agencyApproveId,
                {
                  status: 2,
                },
                (response) => {
                  setApprovePopVisible(false);
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg,
                  });
                  fetchAgencyList(
                    {
                      limit: PAGE_LIMITS.agencyList,
                      pageNumber: currentPage,
                      sortType,
                      filterType,
                    },
                    () => {
                      stopLoader();
                      console.log("success");
                    },
                    (er) => {
                      console.log("failure", er);
                    }
                  );
                },
                (response) => {
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg,
                  });
                  stopLoader();
                  setOpenSnackbar(true);
                }
              );
            }}
            onRejection={() => setApprovePopVisible(false)}
          />
          <DecisionPopup
            modalVisibility={rejectPopupVisible}
            dialogContent={STRINGS.REJECT_AGENCY_CONTENT}
            dialogTitle={STRINGS.REJECT_AGENCY}
            confirmButtonTitle={STRINGS.CONFIRM}
            rejectButtonTitle={STRINGS.CANCEL}
            toggleDialogModal={() => setRejectPopVisible(!rejectPopupVisible)}
            onConfirmation={() => {
              updateAgencyStatus(
                agencyRejectId,
                {
                  status: 3,
                },
                (response) => {
                  setRejectPopVisible(false);
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg,
                  });
                  fetchAgencyList(
                    {
                      limit: PAGE_LIMITS.agencyList,
                      pageNumber: currentPage,
                      sortType,
                      filterType,
                    },
                    () => {
                      stopLoader();
                      console.log("success");
                    },
                    (er) => {
                      console.log("failure", er);
                    }
                  );
                },
                (response) => {
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg,
                  });
                  stopLoader();
                  setOpenSnackbar(true);
                }
              );
            }}
            onRejection={() => setRejectPopVisible(false)}
          />

          <CustomTable
            rows={agencyList && agencyList.data ? agencyList.data : []}
            rowsPerPage={10}
            showProfile={false}
            columns={LAYOUTS.ecrAgency}
            activePage={1}
            sortByKey={sortKey}
            sortType={sortType}
            viewRequired={true}
            EDIT_ICON={""}
            onViewDetails={(data) => {
              setAgencyListPage(currentPage);
              history.push(`${ROUTES.AGENCY_DETAILS}?id=${data}`);
            }}
            //  onRowClick={(id) => {
            //   setAgencyListPage(currentPage);
            //   console.log("history.push(ROUTES.AGENCY_DETAILS)", id);
            //   history.push(`${ROUTES.AGENCY_DETAILS}?id=${id}`);
            // }}
            DELETE_ICON={""}
            onEditClick={(data) => {}}
            onAccept={(data) => {
              setAgencyApproveId(data._id)
              setApprovePopVisible(true);
            }}
            onReject={(data) => {
               setAgencyRejectId(data._id)
               setRejectPopVisible(true);
            }}
            onDeleteClick={(id) => {
              setTargetId(id);
              setDeletePopVisible(true);
            }}
            emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
            TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
            setSortKey={setSortKey}
          />
          {agencyList && agencyList.totalDocuments > PAGE_LIMITS.agencyList && (
            <CustomPagination
              limit={PAGE_LIMITS.agencyList}
              currentPage={currentPage}
              itemsCount={
                agencyList && agencyList.data ? agencyList.data.length : 0
              }
              totalPages={agencyList.totalDocuments}
              onPageChange={(page) => {
                setCurrentPage(page.selected + 1);
                setLastActivePage(page.selected + 1);
                fetchAgencyList(
                  {
                    limit: PAGE_LIMITS.agencyList,
                    pageNumber: page.selected + 1,
                    sortType,
                    filterType,
                  },
                  () => {
                    stopLoader();
                    console.log("success");
                  },
                  (er) => {
                    console.log("failure", er);
                  }
                );
              }}
            />
          )}
        </div>
      </React.Fragment>
    );
} 