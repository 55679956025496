import { takeLatest, put, all } from 'redux-saga/effects';
import {
    GET_STAFF_LIST,
    ADD_ECR_STAFF,
    UPDATE_USER_DETAILS,
    UPDATE_STAFF_DETAILS,
    DELETE_STAFF,
    startLoader,
    stopLoader,
    setStaffList,
    setAuthorization,
    setPlatformType,
} from '../actions';
import { STATUS_CODE } from '../../shared/constants';
import {
    getRequest,
    putRequest,
    postRequest,
    deleteRequest
} from '../../helpers';
import api  from '../../shared/api/api.js';

function* addEcrStaff({ data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield postRequest({ API: `${api.URL.STAFF}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(stopLoader());
            success(response.data);
        }
        
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};

function* getEcrStaffList({ data, success, failure }) {
    try {
        let { limit, sortType, searchString, filterType,pageNumber } = data;
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.STAFF}?limit=${limit}&pageNumber=${pageNumber}&sortType=${sortType}&filterType=${filterType}${!!searchString?`&searchString=${searchString}`:''}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setStaffList(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};

function* updateEcrStaff({ staffId, data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield putRequest({ API: `${api.URL.STAFF}/${staffId}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(stopLoader());
            success(response.data);
        }

    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};

function* deleteEcrStaff({ staffId, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield deleteRequest({ API: `${api.URL.STAFF}/${staffId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            success(response.data);
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};

function* updateUserDetails({ data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield postRequest({ API: `${api.URL.UPDATE_PROFILE}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(stopLoader());
            success(response.data);
        }
        
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};

function* StaffSaga() {
    yield all([
        takeLatest(ADD_ECR_STAFF, addEcrStaff),
        takeLatest(GET_STAFF_LIST, getEcrStaffList),
        takeLatest(DELETE_STAFF,deleteEcrStaff),
        takeLatest(UPDATE_STAFF_DETAILS,updateEcrStaff),
        takeLatest(UPDATE_USER_DETAILS,updateUserDetails),
    ]);
};

export default StaffSaga;