import { connect } from 'react-redux';
import { Screen } from "./screen";
import { reset } from 'redux-form';
import { updateUserDetails, stopLoader,getPromoList,addPromo,deletePromoCode,updatePromoCode } from '../../redux/actions';

const mapStateToProps = (state) => {
  return ({
    promoList:state.PromotionReducer.promoList
  });
}
const mapDispatchToProps = (dispatch) => {
  return {
    stopLoader: () => dispatch(stopLoader()),
    resetForm:(formName)=>dispatch(reset(formName)),
    updateUserDetails: (data, success, failure) => dispatch(updateUserDetails(data, success, failure)),
    getPromoList: (data, success, failure) => dispatch(getPromoList(data, success, failure)),
    addPromo:(data,success,failure)=>dispatch(addPromo(data,success,failure)),
    updatePromoCode:(data,success,failure)=>dispatch(updatePromoCode(data,success,failure)),
    deletePromoCode:(data,success,failure)=>dispatch(deletePromoCode(data,success,failure)),
  }
}
export const PromoSettingScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);