import { SET_STAFF_LIST } from '../actions';

const initialStaffState = {
    staffList: [],
};

const StaffReducer = (state = { ...initialStaffState }, action) => {
    switch (action.type) {
        case SET_STAFF_LIST:
            return {
                ...state,
                staffList: action.data
            };
        default:
            return state;
    };
};

export default StaffReducer;