import React, { useState,useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import StarRatings from 'react-star-ratings';
import "./style.scss";
import {
    PAGE_TITLES,
    PAGE_LIMITS,
    LAYOUTS,
    STRINGS,
    TABLE_ARROW_DOWN,
    ROUTES,
} from '../../shared/constants';
import {
    CustomTable,
  CustomPagination,
  DecisionPopup
} from '../../components/atoms';
import { SnackbarWrapper } from '../../components/molecules';
//import './style.scss';

export const Screen = ({
    history,
    driverList,
    stopLoader,
    driverProfile,
    driverRatings,
    driverUpcomingTrips,
    driverPastTrips,
    fetchDriverDetails,
    deleteDriverDetails,
    fetchDriverRating,
    fetchDriverUpcomingTrips,
    fetchDriverPastTrips,
    chargeUserDriver
}) => {
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [popupVisible, setPopVisible] = useState(false);
    const [isOpenChargePopup, setIsOpenChargePopup] = useState(false);
    const [charge, setCharge] = useState(30);
    const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);
    const [currentPagePast, setCurrentPagePast] = useState(1);
    const [sortKey, setSortKey] = useState('name');
    // const [lastActivePagePast, setLastActivePagePast] = useState(1);
    // const [lastActivePageUpcoming, setLastActivePageUpcoming] = useState(1);
    const sortType = 1;
    const driverId=history.location.search.split('=')[1]
    // const [searchString, setSearchString] = useState('');
    const currentIndexUpcoming = 0;
    const currentIndexPast = 0;
    // const [newData, setNewData] = useState('');
    const [snackbarData, setSnackBarData] = useState({
      variant: "",
      message: "",
    });

    useEffect(() => {
        fetchDriverDetails(
            driverId,
            {},
          () => {
             stopLoader();
             console.log("success");
           },
           (er) => {
             console.log("failure", er);
           }
        );
        fetchDriverRating(
          driverId,
            {
              limit: PAGE_LIMITS.driverList,
            },
          () => {
            stopLoader();
            console.log("success");
          },
          (er) => {
            console.log("failure", er);
          }
        );
         fetchDriverUpcomingTrips(
           driverId,
           {
             limit: PAGE_LIMITS.driverList,
             index:currentIndexUpcoming,
           },
           () => {
             stopLoader();
             console.log("success");
           },
           (er) => {
             console.log("failure", er);
           }
         );
         fetchDriverPastTrips(
           driverId,
           {
             limit: PAGE_LIMITS.driverList,
             index:currentIndexPast,
           },
           () => {
             stopLoader();
             console.log("success");
           },
           (er) => {
             console.log("failure", er);
           }
         );
    }, []);

  const handleChargeChange = (e) => {
    const value = e.target.value.replace(/[^\d]/,'');
    setCharge(value);
  }
  
  const ratings =
    driverRatings &&
    driverRatings.data &&
    driverRatings.data[0] &&
    driverRatings.data[0].rateForDriver
      ? driverRatings.data[0].rateForDriver
      : 0;
    return (
      <React.Fragment>
        <Helmet>
          <title>{PAGE_TITLES.driverDetails}</title>
        </Helmet>
        <div className="container-fluid">
          <div className={"card border-0 shadow"}>
            <div className={"card-body"}>
              <div class="card-header pt-0">
                <div className="row driver_title">
                  <div className="col-sm-4">
                    {driverProfile && (
                      <div className={"driver_info"}>
                        <h3 className="text-capitalize mb-3">Driver details</h3>
                        <h5>{driverProfile.name}{" "}{driverProfile.surname ? driverProfile.surname : ''}</h5>
                        <span className="email_address">
                          {driverProfile.email && driverProfile.email}
                        </span>
                        {driverProfile.city && (
                          <p>
                            {driverProfile.city && driverProfile.city} ,{" "}
                            {driverProfile.country && driverProfile.country}
                          </p>
                        )}
                        {driverProfile.phoneNumber && (
                          <h6 className={"user-role"}>
                            {driverProfile.phoneNumber.code.length === 2 && "+"}
                            {driverProfile.phoneNumber.code.substring(
                              0,
                              3
                            )}{" "}
                            {driverProfile.phoneNumber.phone}
                          </h6>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-8 text-md-right">
                    <button
                      className={"btn btn-md btn-primary"}
                      onClick={() => setPopVisible(true)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>

              <SnackbarWrapper
                visible={openSnackBar}
                onClose={() => setOpenSnackbar(false)}
                variant={snackbarData.variant}
                message={snackbarData.message}
              />
              <DecisionPopup
                modalVisibility={popupVisible}
                dialogContent={STRINGS.DELETE_CONTENT}
                dialogTitle={STRINGS.DELETE_DRIVER}
                confirmButtonTitle={STRINGS.CONFIRM}
                rejectButtonTitle={STRINGS.CANCEL}
                toggleDialogModal={() => setPopVisible(!popupVisible)}
                onConfirmation={() => {
                  deleteDriverDetails(
                    {
                      driverId: driverId,
                    },
                    (response) => {
                      setPopVisible(false);
                      setSnackBarData({
                        variant: response.status ? "success" : "error",
                        message: response.msg,
                      });
                      history.push(ROUTES.DRIVERS);
                    },
                    (response) => {
                      setSnackBarData({
                        variant: response.status ? "success" : "error",
                        message: response.msg,
                      });
                      stopLoader();
                      setOpenSnackbar(true);
                    }
                  );
                }}
                onRejection={() => setPopVisible(false)}
              />

              <hr className="m-0" />
              <DecisionPopup
                modalVisibility={isOpenChargePopup}
                dialogContent={
                  <Fragment>
                    <input
                      type="number"
                      className="form-control"
                      min={0}
                      value={charge}
                      onChange={handleChargeChange} 
                      placeholder={STRINGS.CHARGE_USER_INPUT_PLACEHOLDER}
                    />
                    <h6 className="text-md-right small">{STRINGS.CHARGE_USER_SUBTITLE}</h6>
                  </Fragment>
                }
                dialogTitle={STRINGS.CHARGE_USER_TITLE}
                confirmButtonTitle={STRINGS.CONFIRM}
                rejectButtonTitle={STRINGS.CANCEL}
                toggleDialogModal={() => setIsOpenChargePopup(!isOpenChargePopup)}
                onConfirmation={() => {
                  chargeUserDriver(
                    {
                      driverId: driverId,
                      amount: charge
                    },
                    (response) => {
                      setPopVisible(false);
                      setSnackBarData({
                        variant: response.status ? "success" : "error",
                        message: response.msg,
                      });
                      setOpenSnackbar(true);
                      setCharge(30);
                      setIsOpenChargePopup(false);
                    },
                    (response) => {
                      setSnackBarData({
                        variant: response.status ? "success" : "error",
                        message: response.msg,
                      });
                      stopLoader();
                      setOpenSnackbar(true);
                      setCharge(30);
                      setIsOpenChargePopup(false);
                    }
                  );
                }}
                onRejection={() => setIsOpenChargePopup(false)}
              />
              <div className="row driver_title">
                  <div className="col-sm-3">
                    <div className="driver_rating">
                      <h5>Driver Ratings</h5>
                      <StarRatings
                        rating={ratings}
                        readonly={true}
                        starRatedColor="#fac802"
                        starEmptyColor="#808080"
                        starHoverColor="none"
                        starDimension="30px"
                        starSpacing="1px"
                        //changeRating={() => {}}
                        numberOfStars={5}
                        name="rating"
                      />
                    </div>
                  </div>
                  <div className="col-sm-8 driver_rating">
                    <button
                      className={"btn btn-md btn-primary"}
                      onClick={() => setIsOpenChargePopup(true)}
                    >
                      Charge User
                    </button>
                  </div>
              </div>
            
              <hr className="m-0" />

              <div className={"row pt-3"}>
                <>
                  {driverUpcomingTrips && (
                    <div className={"col-md-12 mb-3"}>
                      <h5 class="table_title">Driver Upcoming Trips</h5>
                      <CustomTable
                        rows={
                          driverUpcomingTrips ? driverUpcomingTrips.trips : []
                        }
                        rowsPerPage={10}
                        showProfile={false}
                        columns={LAYOUTS.trips}
                        activePage={1}
                        sortByKey={sortKey}
                        sortType={sortType}
                        onEditClick={(data) => {}}
                        onDeleteClick={(id) => {}}
                        emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                        setSortKey={setSortKey}
                      />
                      {driverUpcomingTrips &&
                        driverUpcomingTrips.totalCount > PAGE_LIMITS.driverList && (
                          <CustomPagination
                            limit={PAGE_LIMITS.driverList}
                            currentPage={currentPageUpcoming}
                            itemsCount={
                              driverUpcomingTrips && driverUpcomingTrips.trips
                                ? driverUpcomingTrips.trips.length
                                : 0
                            }
                            totalPages={driverUpcomingTrips.totalCount}
                            onPageChange={(page) => {
                              setCurrentPageUpcoming(page.selected + 1);
                              // setLastActivePageUpcoming(page.selected + 1);
                              fetchDriverUpcomingTrips(
                                driverId,
                                {
                                  limit: PAGE_LIMITS.driverList,
                                  index: page.selected,
                                },
                                () => {
                                  stopLoader();
                                  console.log("success");
                                },
                                (er) => {
                                  console.log("failure", er);
                                }
                              );
                            }}
                          />
                        )}
                    </div>
                  )}

                  {driverPastTrips && (
                    <div className={"col-md-12"}>
                      <h5 className="table_title">Driver Past Trips</h5>
                      <CustomTable
                        rows={driverPastTrips ? driverPastTrips.trips : []}
                        rowsPerPage={10}
                        showProfile={false}
                        columns={LAYOUTS.trips}
                        activePage={1}
                        sortByKey={sortKey}
                        sortType={sortType}
                        onEditClick={(data) => {}}
                        onDeleteClick={(id) => {}}
                        emptyDataMessage={STRINGS.NO_RECORDS_FOUND}
                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                        setSortKey={setSortKey}
                      />
                      {driverPastTrips && driverPastTrips.totalCount > PAGE_LIMITS.driverList && (
                        <CustomPagination
                          limit={PAGE_LIMITS.driverList}
                          currentPage={currentPagePast}
                          itemsCount={
                            driverPastTrips && driverPastTrips.trips
                              ? driverPastTrips.trips.length
                              : 0
                          }
                          totalPages={driverPastTrips.totalCount}
                          onPageChange={(page) => {
                            setCurrentPagePast(page.selected + 1);
                            // setLastActivePagePast(page.selected + 1);
                            fetchDriverPastTrips(
                              driverId,
                              {
                                limit: PAGE_LIMITS.driverList,
                                index: page.selected,
                              },
                              () => {
                                stopLoader();
                                console.log("success");
                              },
                              (er) => {
                                console.log("failure", er);
                              }
                            );
                          }}
                        />
                      )}
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}