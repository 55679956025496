import { connect } from "react-redux";
import { Screen } from "./screen";
import {
  fetchAgencyList,
  stopLoader,
  fetchAgencyTokens,
  deleteAgencyDetails,
  fetchAgencyUpcomingTrips,
  fetchAgencyPastTrips,
  fetchAgencyProfile
} from "../../redux/actions";

const mapStateToProps = (state) => {
  return {
    agencyList: state.AgencyReducer.agencyList,
    agencyTokens: state.AgencyReducer.agencyTokens,
    agencyUpcomingTrips: state.AgencyReducer.agencyUpcomingTrips,
    agencyPastTrips:state.AgencyReducer.agencyPastTrips,
    agencyProfile:state.AgencyReducer.agencyProfile,
    isLoading: state.CommonReducer.loader
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    stopLoader: () => dispatch(stopLoader()),
    fetchAgencyTokens: (data, success, failure) =>
      dispatch(fetchAgencyTokens(data, success, failure)),
    fetchAgencyList: (data, success, failure) =>
      dispatch(fetchAgencyList(data, success, failure)),
    deleteAgencyDetails: (data, success, failure) =>
      dispatch(deleteAgencyDetails(data, success, failure)),
    fetchAgencyProfile: (agencyId, data, success, failure) =>
      dispatch(fetchAgencyProfile(agencyId, data, success, failure)),
    fetchAgencyUpcomingTrips: (agencyId, data, success, failure) =>
      dispatch(fetchAgencyUpcomingTrips(agencyId, data, success, failure)),
    fetchAgencyPastTrips: (agencyId, data, success, failure) =>
      dispatch(fetchAgencyPastTrips(agencyId, data, success, failure)),
  };
};
export const AgencyDetailsScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
