// const SERVER_URL = "http://172543eefb0d.ngrok.io" || "http://localhost:3000"; //Live URL
const SERVER_URL = process.env.REACT_APP_BASE_URL || "https://api.easycarrelo.co.nz"; //Live URL
// const SERVER_URL = process.env.REACT_APP_BASE_URL || "https://a14a-112-196-113-2.ngrok.io"; //Live URL


const API_VERSION = process.env.REACT_API_VERSION || "/v1";

module.exports = {
  URL: {
    SERVER_URL: SERVER_URL,
    LOGIN: SERVER_URL + API_VERSION + "/admin/login",
    STAFF: SERVER_URL + API_VERSION + "/staff",
    FORGOT_PASSWORD: SERVER_URL + API_VERSION + "/admin/forgotPassword",
    UPDATE_PROFILE: SERVER_URL + API_VERSION + "/staff/updateProfile",
    DRIVER: SERVER_URL + API_VERSION + "/driver",
    DRIVER_PROFILE: SERVER_URL + API_VERSION + "/driver/profile",
    DRIVER_RATING: SERVER_URL + API_VERSION + "/driver/ratings",
    DRIVER_PAST_TRIPS: SERVER_URL + API_VERSION + "/driver/past-trips",
    DRIVER_UPCOMING_TRIPS: SERVER_URL + API_VERSION + "/driver/upcoming-trips",
    DELETE_DRIVER_OR_AGENCY_DETAILS: SERVER_URL+ API_VERSION + "/admin/deleteUser",
    AGENCY: SERVER_URL + API_VERSION + "/agency",
    AGENCY_TOKENS: SERVER_URL + API_VERSION + "/agency/get-tokens",
    AGENCY_PROFILE:SERVER_URL + API_VERSION + "/agency/profile",
    PROMOTION: SERVER_URL + API_VERSION + "/promoCode",
    PACKAGE: SERVER_URL + API_VERSION + "/package",
    AGENCY_PAST_TRIPS: SERVER_URL + API_VERSION + "/agency/past-trips",
    AGENCY_UPCOMING_TRIPS: SERVER_URL + API_VERSION + "/agency/upcoming-trips",
    CHARGE_DRIVER: SERVER_URL+ API_VERSION + "/driver/charge",
    DELETE_DIRIVER: SERVER_URL+ API_VERSION + "   /v1/listing/",




  },
};