import React, { useState } from "react";
import { LoginReduxForm } from './form';
import { Helmet } from "react-helmet";
import appLogo from '../../assets/img/logo.png';
import "./style.scss";
import { FormWrapper } from '../../components/hoc/form-wrapper';
import { SnackbarWrapper } from '../../components/molecules';
import {
    LABELS,
    PAGE_TITLES
} from '../../shared/constants';


export const Screen = ({
    checkLogin,
    history,
    rememberCredentials,
    rememberMe,
    stopLoader
}) => {
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.login}</title>
            </Helmet>
            <div className={'container'}>
                <div className={'front-form'}>
                    <div className={'front_form_group'}>
                        <div className="header-main text-center">
                            <span className="logo">
                                <img src={appLogo} alt="ECR" className="img-fluid d-inline-block" width="200" />
                            </span>
                        </div>
                        <FormWrapper>
                            <div className="col-md-12 position-relative">
                                <div className="form_title text-center">
                                    <SnackbarWrapper
                                        visible={openSnackBar}
                                        onClose={() => setOpenSnackbar(false)}
                                        variant={snackbarData.variant}
                                        message={snackbarData.message}
                                    />
                                    {/* <i><img src="/static/media/forgot_icon.3c8499a5.png" alt="" className="img-fluid" width="130px" /></i> */}
                                    <h3 className="mb-4">{LABELS.login}</h3>
                                </div>
                                <LoginReduxForm onSubmit={(credentials) => {
                                    checkLogin({
                                        email: credentials.email,
                                        password: credentials.password
                                    }, () => {
                                        stopLoader();
                                        // history.push(ROUTES.DASHBOARD);
                                    },
                                        (response) => {
                                            setSnackBarData({
                                                variant: response.status ? 'success' : 'error',
                                                message: response.msg
                                            });
                                            setOpenSnackbar(true)
                                        });
                                }}
                                    credentials={credentials}
                                    onEmailChange={(value) => setCredentials({
                                        ...credentials,
                                        email: value
                                    })}
                                    onPasswordChange={(value) => setCredentials({
                                        ...credentials,
                                        password: value
                                    })}
                                />
                            </div>
                        </FormWrapper>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}