import { takeLatest, put, all } from 'redux-saga/effects';
import {
    GET_AGENCY_LIST,
    GET_AGENCY_TOKENS,
    UPDATE_STATUS,
    startLoader,
    GET_AGENCY_PROFILE,
    stopLoader,
    setAgencyList,
    setAuthorization,
    setAgencyTokens,
    setPlatformType,
    setAgencyProfile,
    DELETE_AGENCY_DETAILS,
    FETCH_AGENCY_PAST_TRIPS,
    FETCH_AGENCY_UPCOMING_TRIPS,
    setAgencyPastTrips,
    setAgencyUpcomingTrips
} from '../actions';
import { STATUS_CODE } from '../../shared/constants';
import {
    getRequest,putRequest
} from '../../helpers';
import api  from '../../shared/api/api.js';

function* getEcrAgencyList({ data, success, failure }) {

    try {
        let { limit, sortType, searchString, filterType,pageNumber } = data;
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.AGENCY}?limit=${limit}&pageNumber=${pageNumber}&sortType=${sortType}&filterType=${filterType}${!!searchString?`&searchString=${searchString}`:''}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setAgencyList(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* deleteEcrAgencyDetails({ data,success, failure }) {
    try {
        yield put(startLoader());
        const response = yield putRequest({ API: `${api.URL.DELETE_DRIVER_OR_AGENCY_DETAILS}`,DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
         if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(stopLoader());
            success(response.data);
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* getEcrAgencyUpcomingTrips({ agencyId,data, success, failure }) {
    try {
         let { limit,index } = data;
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.AGENCY_UPCOMING_TRIPS}?limit=${limit}&index=${index}&agencyId=${agencyId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setAgencyUpcomingTrips(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* getEcrAgencyPastTrips({ agencyId,data, success, failure }) {
    try {
         let { limit,index } = data;
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.AGENCY_PAST_TRIPS}?limit=${limit}&index=${index}&agencyId=${agencyId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setAgencyPastTrips(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* getEcrAgencyTokens({ agencyId,data,success,failure})
{
    try {
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.AGENCY_TOKENS}?agencyId=${agencyId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setAgencyTokens(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }   
};

function* getEcrAgencyProfile({agencyId,data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.AGENCY_PROFILE}?agencyId=${agencyId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure();
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(setAgencyProfile(response.data));
            success();
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }
};
function* updateStatus({ agencyId, data, success, failure }) {
    try {
        yield put(startLoader());
        const response = yield putRequest({ API: `${api.URL.AGENCY}/${agencyId}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(stopLoader());
            yield put(setAuthorization(null));
            yield put(setPlatformType(null));
        }
        if (response.status === STATUS_CODE.bad) {
            yield put(stopLoader());
            failure(response.data);
        }
        if (response.status === STATUS_CODE.successful) {
            yield put(stopLoader());
            success(response.data);
        }
    }
    catch (error) {
        yield put(stopLoader());
        failure(error);
    }   
};
function* AgencySaga() {
    yield all([
        takeLatest(GET_AGENCY_LIST, getEcrAgencyList),
        takeLatest(GET_AGENCY_TOKENS, getEcrAgencyTokens),
        takeLatest(UPDATE_STATUS, updateStatus),
        takeLatest(DELETE_AGENCY_DETAILS, deleteEcrAgencyDetails),
        takeLatest(GET_AGENCY_PROFILE, getEcrAgencyProfile),
        takeLatest(FETCH_AGENCY_PAST_TRIPS, getEcrAgencyPastTrips),
          takeLatest(FETCH_AGENCY_UPCOMING_TRIPS,getEcrAgencyUpcomingTrips),
    ]);
};

export default AgencySaga;