import { STRINGS, VALIDATION_MESSAGES, EMAIL_REGX, NAME_REGX } from '../../shared/constants';

const validator = values => {
  const errors = {};
  if (!values[STRINGS.NAME_INPUT]) {
    errors[STRINGS.NAME_INPUT] =
      VALIDATION_MESSAGES.NAME_REQUIRED;
  }
  else if (values[STRINGS.NAME_INPUT] && !(values[STRINGS.NAME_INPUT].trim())) {
      errors[STRINGS.NAME_INPUT] =
      VALIDATION_MESSAGES.NAME_REQUIRED;
  }
  else if (
    !NAME_REGX.test(
      values[STRINGS.NAME_INPUT]
    )
  ) {
    errors[STRINGS.NAME_INPUT] =
      VALIDATION_MESSAGES.NAME_INVALID;
  }
    
    
    if (!values[STRINGS.EMAIL_INPUT_NAME]) {
    errors[STRINGS.EMAIL_INPUT_NAME] =
      VALIDATION_MESSAGES.EMAIL_REQUIRED;
  } else if (
    !EMAIL_REGX.test(
      values[STRINGS.EMAIL_INPUT_NAME].toLowerCase()
    )
  ) {
    errors[STRINGS.EMAIL_INPUT_NAME] =
      VALIDATION_MESSAGES.EMAIL_INVALID;
  }
    
    
  if (!values[STRINGS.PASSWORD_INPUT_NAME]) {
    errors[STRINGS.PASSWORD_INPUT_NAME] =
      VALIDATION_MESSAGES.PASSWORD_REQUIRED;
  }

  return errors;
};

export default validator;
