import { connect } from 'react-redux';
import { Screen } from "./screen";
import { reset } from 'redux-form';
import { updateUserDetails, stopLoader } from '../../redux/actions';

const mapStateToProps = (state) => {
  return ({
  });
}
const mapDispatchToProps = (dispatch) => {
  return {
    stopLoader: () => dispatch(stopLoader()),
    resetForm:(formName)=>dispatch(reset(formName)),
        updateUserDetails: (data, success, failure) => dispatch(updateUserDetails(data, success, failure))
  }
}
export const ProfileScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);