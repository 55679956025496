import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Switch,Route,Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {ScreenHOC} from '../../components/hoc/screen-hoc';
import { makeStyles } from '@material-ui/core/styles';
import { ROUTES, HAMBURGER_ICON, HEADER_LOGO, CLOSE_ICON,STRINGS,ADMIN_DRAWER_ITEMS, } from '../../shared/constants';
import { EcrStaffScreen } from '../../views/ecr-staff';
import { DriverScreen } from '../../views/drivers';
import { AgenciesScreen } from '../../views/agencies';
import { ProfileScreen } from '../../views/profile';
import { PromoSettingScreen } from '../../views/promo-setting';
import { PriceSettingScreen } from '../../views/token-setting';
import { DriverDetailsScreen } from '../../views/driver-details';
import { DecisionPopup } from '../../components/atoms';
import {setAuthorization,stopLoader, setPlatformType } from '../../redux/actions';
import { AgencyDetailsScreen } from '../../views/agency-details';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));
const AdminRouter = ({
  userData,
  setPlatformType,
  setAuthorization
}) => {
    let history = useHistory()
  const classes = useStyles();
  const [popupVisible,setPopVisible]=useState(false)
    return (
        <ScreenHOC
        containerStyle={classes.screenHocContainer}
        childrenStyle={classes.screenContentContainer}
        drawerItems={ADMIN_DRAWER_ITEMS}
        userData={userData}
        headerMenuItem={['Profile','Logout']}
        onClickAction={(changedUrl) => {
          if (changedUrl === ROUTES.LOGOUT) {
            setPopVisible(true);

          }
          else {
            history.push(changedUrl);
          }
        }}
        profileLogoClick={() => {
          history.replace(ROUTES.PROFILE)
        }}
        hamburgerIcon={HAMBURGER_ICON}
        headerLogo={HEADER_LOGO}
        headerLogoAction={() => {
          history.replace(ROUTES.DASHBOARD)
        }}
        backArrow={CLOSE_ICON}
      >
        <DecisionPopup
          modalVisibility={popupVisible}
          dialogContent={STRINGS.LOGOUT_CONTENT}
          dialogTitle={STRINGS.LOGOUT}
          confirmButtonTitle={STRINGS.CONFIRM}
          rejectButtonTitle={STRINGS.CANCEL}
          toggleDialogModal={() => setPopVisible(!popupVisible)}
          onConfirmation={() => {
            setAuthorization(null);
            setPlatformType(null);
            history.push(ROUTES.LOGIN)
          }}
          onRejection={() => setPopVisible(false)}
        />
            <Switch>
                <Route exact path={ROUTES.ECR_STAFF} component={EcrStaffScreen} />
                <Route exact path={ROUTES.DRIVERS} component={DriverScreen}/>
                <Route exact path={ROUTES.AGENCIES} component={AgenciesScreen} />
                <Route exact path={ROUTES.PROFILE} component={ProfileScreen}/>
                <Route exact path={ROUTES.PROMO_SETTING} component={PromoSettingScreen} />
                <Route exact path={ROUTES.TOKEN_PRICE_SETTING} component={PriceSettingScreen} />
                <Route exact path={ROUTES.DRIVER_DETAILS} component={DriverDetailsScreen} />
                <Route exact path={ROUTES.AGENCY_DETAILS} component={AgencyDetailsScreen}/>
                <Redirect to={ROUTES.ECR_STAFF} />
            </Switch>
       </ScreenHOC>
    );
}

const mapStateToProps = (state) => {
  return ({
    userData: state.CommonReducer.userData,
    userToken: state.CommonReducer.userToken,
    platformType: state.CommonReducer.platformType,
  });
}
const mapDispatchToProps = (dispatch) => {
  return {
    setAuthorization: (userData) => dispatch(setAuthorization(userData)),
    setPlatformType: (platformType) => dispatch(setPlatformType(platformType)),
    stopLoader: () => dispatch(stopLoader()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminRouter);