import { connect } from 'react-redux';
import { Screen } from "./screen";
import { fetchAgencyList, stopLoader,setAgencyListPage,updateAgencyStatus,deleteAgencyDetails } from '../../redux/actions';


const mapStateToProps = (state) => {
  return ({
    agencyList: state.AgencyReducer.agencyList,
    agencyListPage:state.AgencyReducer.agencyListPage
  });
}
const mapDispatchToProps = (dispatch) => {
  return {
    stopLoader: () => dispatch(stopLoader()),
    fetchAgencyList: (data, success, failure) =>
      dispatch(fetchAgencyList(data, success, failure)),
    setAgencyListPage: (page) => dispatch(setAgencyListPage(page)),
    updateAgencyStatus: (agencyId, data, success, failure) =>
      dispatch(updateAgencyStatus(agencyId, data, success, failure)),
    deleteAgencyDetails: (data, success, failure) =>
      dispatch(deleteAgencyDetails(data, success, failure)),
  };
  }

export const AgenciesScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);