import React, { useState } from 'react';
import { Loader } from '../../atoms/loader';
import AppDrawer from '../../atoms/app-drawer';
import './style.scss';
import { connect } from 'react-redux';
import { ROUTES, STRINGS } from '../../../shared/constants';
import { AppHeader } from '../../atoms/app-header';

const HOC = ({
    containerStyle,
    containerId = 'screen-hoc-container',
    contentId = 'screen-content',
    childrenStyle,
    onClickAction = () => { },
    profileLogoClick = () => { },
    drawerId = 'app-drawer',
    drawerItems,
    companyLogo,
    topShape,
    userData,
    bottomShape,
    hamburgerIcon,
    headerLogo,
    children,
    loader = false,
    backArrow,
    headerLogoAction,
}) => {
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth < 1024) {
                setDrawerVisible(false);
                return;
            }
            else if (window.innerWidth >= 1024) {
                setDrawerVisible(true);
                return;
            }
        });
    })

    const [drawerVisible, setDrawerVisible] = useState(true);
    const responsiveScroll = () => { }
    return (
        <div id={containerId} className={containerStyle} onScroll={responsiveScroll}>
            <AppHeader
                hamburgerIcon={hamburgerIcon}
                headerLogo={headerLogo}
                userData={userData}
                profileLogoClick={() => {
                    if (drawerVisible&&(window.innerWidth < 1025)) {                        
                        setDrawerVisible(false);
                    }
                    profileLogoClick()
                }}
                hamburgerVisible={drawerVisible ? window.innerWidth < 1025 ? true : false : true}
                menuItems={[
                    // ...HEADER_MENU_ITEMS,
                    {
                        label: STRINGS.PRIVACY_POLICY,
                        routeUrl: ROUTES.PRIVACY_POLICY,
                        onClick: changedUrl => { onClickAction(changedUrl) }
                    },
                    {
                        label: STRINGS.LOG_OUT,
                        routeUrl: ROUTES.LOGOUT,
                        onClick: (changedUrl) => {
                            onClickAction(changedUrl)
                        }
                    }
                ]}
                onHamburgerClick={(e) => {
                    setDrawerVisible(!drawerVisible);
                    e.stopPropagation()
                }}
            />
            <AppDrawer
                drawerId={drawerId}
                drawerItems={drawerItems}
                companyLogo={companyLogo}
                topShape={topShape}
                drawerVisible={drawerVisible}
                onClickAction={(changedUrl) => {
                    onClickAction(changedUrl);
                    window.innerWidth < 1024 && setDrawerVisible(false)
                }}
                bottomShape={bottomShape}
                toggleDrawer={() => { setDrawerVisible(!drawerVisible); }}
                backArrow={backArrow}
            />

            <div id={contentId} className={childrenStyle} onClick={(e) => { window.innerWidth < 1024 && setDrawerVisible(false) }}>
                {children}
                {loader &&
                    <Loader />
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return ({
    });
}
const mapDispatchToProps = (dispatch) => {
    return {

    }
}
export const ScreenHOC = connect(mapStateToProps, mapDispatchToProps)(HOC);