import { combineReducers } from "redux";
import CommonReducer from './common';
import StaffReducer from './staff';
import DriverReducer from './driver';
import AgencyReducer from './agency';
import PackageReducer from './package';
import PromotionReducer from './promotion';
import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
    CommonReducer,
    form: formReducer,
    StaffReducer,
    DriverReducer,
    AgencyReducer,
    PackageReducer,
    PromotionReducer
});

export default rootReducer;